// External
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import moment from 'moment';

// Internal
import { SubscriptionMgmtService } from '../../requests/connect-subscription-service/connect-subscription.service';
import { ValuesService, IName } from '../../../../common/values/values.service';
import { DeviceAllocationMgmtResponseModel, BundleModel } from '../../../../common/models/Services.model';
import { LanguageService } from '../../core/language.service';
import { ProfilesService } from '../profiles/profiles.service';
import { AppLevels, SubscriptionsV3Prefix, SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { ProductsConfigService } from '../../../../common/config/products.config.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { WebmailProtectionSlots } from '../../../models/security/WebmailProtection.model';

@Injectable({
    providedIn: 'root'
})

export class SubscriptionsService {

    tempSubscriptions: any = {};
    originalSubscriptionsResponse = [];
    subscriptions: any = {};

    allServices = [];
    convertedServiceIds = new Set();

    private readonly onlistSubscriptions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdate_subscriptions = true;

    constructor(
        private readonly subscriptionMgmtService: SubscriptionMgmtService,
        private readonly router: Router,
        private readonly valuesService: ValuesService,
        private readonly languageService: LanguageService,
        private readonly profilesService: ProfilesService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly usefulService: UsefulService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly utileCommonService: UtilsCommonService
    ) {}

    addCovertedServiceId(serviceId) {
        this.convertedServiceIds.add(serviceId);
    }

    serviceIdWasConverted(serviceId) {
        return this.convertedServiceIds.has(serviceId);
    }

    setHasPaidSubscription(bundle: BundleModel) {
        this.subscriptions.hasPaidSubscription = this.subscriptions.hasPaidSubscription
                                                || (bundle.type === this.subscriptionsValuesService.type.end_user
                                                    && bundle.status === this.subscriptionsValuesService.status.active);
    }

    setHasBIP(bundle: BundleModel) {
        const applications = bundle?.applications ?? [];
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of applications) {
            this.subscriptions.hasBIP = this.subscriptions.hasBIP || app.app_id === this.valuesService.appBIP;
        }
    }

    isTrialBundle(bundle: BundleModel) {
        return bundle.type === this.subscriptionsValuesService.type.trial || bundle.type === this.subscriptionsValuesService.type.nfr;
    }

    isPaidBundle(bundle: BundleModel) {
        return bundle.type === this.subscriptionsValuesService.type.end_user;
    }

    isExpired(bundle: BundleModel) {
        return bundle.status === this.subscriptionsValuesService.status.expired;
    }

    isExpiredForLessThan30Days(bundle: BundleModel) {
        return bundle.status === this.subscriptionsValuesService.status.expired && bundle.server_time - bundle.end_date <= this.valuesService.SECONDS_IN_A_DAY*30;
    }

    /**
     * Check if subscription is managed by another account
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     * @returns {boolean}
     */
    public isManagedByOrganization(bundle: BundleModel): boolean {
        return !!(bundle?.source?.type === this.subscriptionsValuesService.sourceType.msp);
    }

    isProtectionBundle(bundle: BundleModel) {
        const applications = this.usefulService.getNested(bundle, [], 'applications');
        for (const app of applications) {
            if (this.valuesService.protectionApps.has(app.app_id)) {
                return true;
            }
        }
        return false;
    }

    isClBundle(bundle: BundleModel) {
        const applications = this.usefulService.getNested(bundle, [], 'applications');
        for (const app of applications) {
            if (app.app_id === this.valuesService.appCL && bundle.status === this.subscriptionsValuesService.status.active) {
                return true;
            }
        }
        return false;
    }

    isNonprotectionBundle(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleDPI
                || this.valuesService.vpnServices.has(bundle.bundle_id)
                || bundle.bundle_id === this.valuesService.bundlePM
                || this.valuesService.idTheftProtectionServices.has(bundle.bundle_id);
    }

    setHasTrialSubscription(bundle) {
        if (bundle.type === 'trial' || bundle.type === 'nfr') {
            this.subscriptions.hasTrialSubscription = bundle.end_date - bundle.server_time;
        }
    }

    setHasAllBundlesAreExpired(bundle: BundleModel) {
        if (!this.isExpired(bundle) && !this.isPremiumService(bundle)) {
            this.subscriptions.hasAllBundlesAreExpired = false;
        }
    }

    setHasPaidProtectionBundleExpired(bundle: BundleModel) {
        if (this.isProtectionBundle(bundle) && this.isPaidBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasPaidProtectionBundleExpired = true;
        }
    }

    setHasClBundle(bundle: BundleModel) {
        if (this.isClBundle(bundle)) {
            this.subscriptions.hasClSubscription = true;
        }
    }

    setHasFreeProtectionBundleExpired(bundle) {
        if (this.isProtectionBundle(bundle) && this.isTrialBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasFreeProtectionBundleExpired = true;
        }
    }

    setHasPaidNonprotectionBundleExpired(bundle: BundleModel) {
        if (this.isNonprotectionBundle(bundle) && this.isPaidBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasPaidNonprotectionBundleExpired = true;
        }
    }

    setHasFreeNonprotectionBundleExpired(bundle: BundleModel) {
        if (this.isNonprotectionBundle(bundle) && this.isTrialBundle(bundle) && this.isExpiredForLessThan30Days(bundle)) {
            this.subscriptions.hasFreeNonprotectionBundleExpired = true;
        }
    }

    /**
     * Sets 'hasMspManagedBundle' flag if there is at least one msp managed bundle
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle The bundle object
     */
    private setHasMspManagedBundle(bundle: BundleModel): void {
        if (this.isManagedByOrganization(bundle) && this.profilesService.hasMspOrXspAnyLevel()) {
            this.subscriptions.hasMspManagedBundle = true;
        }
    }

    hasAllBundlesAreExpired() {
        return this.subscriptions.hasAllBundlesAreExpired;
    }

    hasPaidProtectionBundleExpired() {
        return this.subscriptions.hasPaidProtectionBundleExpired;
    }

    hasFreeProtectionBundleExpired() {
        return this.subscriptions.hasFreeProtectionBundleExpired;
    }

    hasPaidNonprotectionBundleExpired() {
        return this.subscriptions.hasPaidNonprotectionBundleExpired;
    }

    hasFreeNonprotectionBundleExpired() {
        return this.subscriptions.hasFreeNonprotectionBundleExpired;
    }

    hasBIP() {
        return this.subscriptions.hasBIP;
    }

    setHasTSMD(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleTSMD) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasTSMD = true;
            } else {
                this.subscriptions.hasTSMDExpired = true;
            }
        }
    }

    setHasBox(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleBox || bundle.bundle_id === this.valuesService.bundleBoxCl || bundle.bundle_id === this.valuesService.bundleBOX2) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasBox = true;
            } else {
                this.subscriptions.hasBoxExpired = true;
            }
        }
    }

    setHasBox2(bundle) {
        if (bundle.bundle_id === this.valuesService.bundleBOX2) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasBox2 = true;
            } else {
                this.subscriptions.hasBox2Expired = true;
            }
        }
    }

    setHasVpn(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (this.valuesService.vpnApps.has(app.app_id)) {
                this.subscriptions.hasVpn = true;

                if (app?.app_params?.level === AppLevels.PREMIUM) {
                    this.subscriptions.hasPremiumVpn = true;
                }
                break;
            }
        }
    }

    setHasParental(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appPA) {
                this.subscriptions.hasParental = true;
                break;
            }
        }
    }

    setHasParentalNCC(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appPANCC) {
                if (app?.app_params?.level === AppLevels.BASIC) {
                    this.subscriptions.hasParentalNCCBasic = true;
                } else {
                    this.subscriptions.hasParentalNCCPremium = true;
                }
                break;
            }
        }
    }

    setHasPremiumSecPlus(bundle) {
        if (bundle.bundle_id === this.valuesService.bundlePSecPlus) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasPremiumSecPlus = true;
            }
        }
    }

    /**
     * Set flag if premium services subscription exists
     * @param {object} bundle
     * @returns {nothing}
     */
    setHasPremiumServices(bundle) {
        if (this.valuesService.techassistProductsDetails[bundle.bundle_id] && bundle.status === this.subscriptionsValuesService.status.active) {
            this.subscriptions.hasPremiumServices = true;
        }
    }

    setHasOnlyIdentitySubscriptions(bundle) {
        // could be expired or not
        if (!this.valuesService.identityProducts.has(bundle.bundle_id)) {
            this.subscriptions.hasOnlyIdentitySubscriptions = false;
        }
    }

    setHasOnlySohoSubscriptions(bundle) {
        if (!this.valuesService.sohoBundles.includes(bundle.bundle_id)) {
            this.subscriptions.hasOnlySohoSubscriptions = false;
        }
    }

    setHasSohoSubscription(bundle) {
        if (this.valuesService.sohoBundles.includes(bundle.bundle_id)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasSohoSubscription = true;
            } else {
                this.subscriptions.hasSohoSubscriptionExpired = true;
            }
        }
    }

    setHasIdTheftProtection(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appIdTheft)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasIdTheftProtection = true;
            } else {
                this.subscriptions.hasIdTheftProtectionExpired = true;
            }
            this.subscriptions.expiryIdTheftProtection = bundle.end_date;
        }
    }

    /**
     * Function that sets 'hasWebmailProtection' flag if bundle includes webmailprotection and is active
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle representing bundle data
     * @returns {void}
     */
    private setHasWebmailProtection(bundle: BundleModel): void {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        for (const app of bundle.applications) {
            if (app.app_id === this.valuesService.appWebmailProtection) {
                this.subscriptions.hasWebmailProtection = true;
            }
        }
    }

    setHasDataPrivacy(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appDIP)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasDataPrivacy = true;
            } else {
                this.subscriptions.hasDataPrivacyExpired = true;
            }
            this.subscriptions.expiryDataPrivacy = bundle.end_date;
        }
    }

    setHasPasswordManager(bundle) {
        if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager)) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                this.subscriptions.hasPasswordManager = true;
            } else {
                if (this.isTrialBundle(bundle)) {
                    this.subscriptions.hasPasswordManagerTrialExpired = true;
                } else {
                    this.subscriptions.hasPasswordManagerExpired = true;
                }
            }
        }
    }

    setHasPasswordManagerCompatible(bundle) {
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return;
        }

        if (this.valuesService.passManagerCompatibleBundles.has(bundle.bundle_id)) {
            this.subscriptions.hasPasswordManagerThreeMonthsCompatible = true;
        } else {
            this.subscriptions.hasPasswordManagerOneMonthCompatible = true;
        }
    }

    setHasAutoRenewalOn() {
        this.subscriptions.hasAutoRenewalOn = true;
    }

    /**
     * Set flag if user has shared subscription if it is eligible for sharing and is payer
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {nothing}
     */
    private setHasSharedSubscription(bundle: BundleModel): void {
        const isEligibleForSharingSub = this.isEligibleForSharingSubscription(bundle);
        const isPayer = this.isSubscriptionPayer(bundle);
        if (isEligibleForSharingSub && isPayer) {
            this.subscriptions.hasSharedSubscription = true;
        }
    }

    getNoBundles() {
        return this.subscriptions.allServices.length;
    }

    getExpiredBundlePasswordManager() {
        for (const bundle of this.subscriptions.filtered.allServices) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                continue;
            }

            if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager) && !this.isTrialBundle(bundle)) {
                return bundle;
            }
        }
        return null;
    }

    getExpiredTrialBundlePasswordManager() {
        for (const bundle of this.subscriptions.filtered.allServices) {
            if (bundle.status === this.subscriptionsValuesService.status.active) {
                continue;
            }

            if (bundle.applications.map(app => app.app_id).includes(this.valuesService.appPassManager) && this.isTrialBundle(bundle)) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Get password manager bundle with shared subscription
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {nothing}
     * @returns {Object | null} bundle or null if bundle does not exist
     */
    public getSharedSubscriptionBundlePasswordManager(): BundleModel|null {
        const services = this.getAllFilteredActiveServices();
        for (const bundle of services) {
            if (this.isEligibleForSharingSubscription(bundle) && this.isPasswordManager(bundle)) {
                return bundle;
            }
        }
        return null;
    }

    /**
     * Gets the number of total and active slots for webmailprotection apps
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {WebmailProtectionSlots} The object that contains the number of total and active slots
     */
    public getWebmailProtectionSlots(): WebmailProtectionSlots {
        const services = this.getActiveBundles();
        const slots: WebmailProtectionSlots = {
            total: 0,
            active: 0
        };

        for (const bundle of services) {
            for (const app of bundle.applications) {
                if (app.app_id === this.valuesService.appWebmailProtection) {
                    slots.total += app.slots.max;
                }
            }

            for (const account of bundle.accounts) {
                const usageSummary = account.usage_summary;
                for (const summary of usageSummary) {
                    if (summary.app_id === this.valuesService.appWebmailProtection) {
                        slots.active++;
                    }
                }
            }
        }

        return slots;
    }

    setArrInfo(bundle) {
        // Used for shorter conditions
        const check = {
            // auto renew
            autoRenew:      bundle?.metadata?.auto_renew,
            // billing cycle
            billingCycle:   bundle?.metadata?.hasOwnProperty('billing_cycle'),
            // billing date
            billingDate:   bundle?.metadata?.hasOwnProperty('billing_date'),
            // end date
            endDate:        bundle?.hasOwnProperty('end_date'),
            // source type
            isZuora:        this.isZuora(bundle),
            isAvangate:     this.isAvangate(bundle),
            isDigitalRiver: this.isDigitalRiver(bundle),
            isGooglePlay:   this.isGooglePlay(bundle),
            isApple:        this.isApple(bundle),
            isOffline:      this.isOffline(bundle),
            // service type
            isSubscription: bundle.service_type === this.subscriptionsValuesService.serviceType.subscription,
            isLicense:      bundle.service_type === this.subscriptionsValuesService.serviceType.license,
            // life cycle
            isRecurrent:    bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.recurrent,
            isOneTime:      bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.one_time,
            isTrial:        bundle.type === this.subscriptionsValuesService.type.trial
        };

        if (!check.isTrial
            && ((check.isLicense && check.isAvangate && check.endDate && check.autoRenew)
                || (check.isLicense && check.isDigitalRiver && check.isOneTime && check.endDate && check.autoRenew)
                || (check.isSubscription && check.isApple && check.isRecurrent && check.billingDate && check.autoRenew)
                || (check.isSubscription && check.isGooglePlay && check.isRecurrent && check.billingDate && check.autoRenew)
                || (check.isSubscription && check.isZuora && check.isRecurrent && check.billingCycle
                    && (check.autoRenew === true || check.autoRenew === undefined)))) {
            bundle.processed.arrON = true;
        }

        if (!bundle?.processed?.arrON
            && !check.isTrial
            && ((check.isLicense && check.isOffline && check.isOneTime && check.endDate)
                || (check.isLicense && check.isAvangate && check.endDate && !check.autoRenew)
                || (check.isLicense && check.isDigitalRiver && check.isOneTime && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isApple && check.isRecurrent && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isGooglePlay && check.isRecurrent && check.endDate && !check.autoRenew)
                || (check.isSubscription && check.isZuora && check.isRecurrent && check.endDate
                    && (check.autoRenew === false || check.autoRenew === undefined)))) {
            bundle.processed.arrOFF = true;
        }
    }

    processSubscriptions() {
        for (const bundle of this.subscriptions.allServices) {

            if (!bundle.processed) {
                bundle.processed = {};
            }

            this.addSharedSubscriptionsInfo(bundle);
            this.setArrInfo(bundle);
            this.setHasBIP(bundle);
            // daca toate sloturile sunt shared, subscriptia actuala e ca si cum nu ar exista pe cont
            if (!bundle.processed.allSlotsAreShared) {
                this.setHasPaidSubscription(bundle);
                this.setHasTrialSubscription(bundle);
                this.setHasTSMD(bundle);
                this.setHasBox(bundle);
                this.setHasBox2(bundle);
                this.setHasParental(bundle);
                this.setHasParentalNCC(bundle);
                this.setHasOnlyIdentitySubscriptions(bundle);
                this.setHasOnlySohoSubscriptions(bundle);
                this.setHasDataPrivacy(bundle);
                this.setHasIdTheftProtection(bundle);
                this.setHasWebmailProtection(bundle);
                this.setHasSohoSubscription(bundle);
                this.setHasVpn(bundle);
                this.setHasPremiumServices(bundle);
                this.setHasPremiumSecPlus(bundle);
                this.setHasPasswordManager(bundle);
                this.setHasPasswordManagerCompatible(bundle);
                this.setHasSharedSubscription(bundle);
                this.processingMethods.first30Days(bundle);
                this.processingMethods.isExtended(bundle);
                this.processingMethods.isMonthlyBundle(bundle);

                this.setHasAllBundlesAreExpired(bundle);
                this.setHasPaidProtectionBundleExpired(bundle);
                this.setHasFreeProtectionBundleExpired(bundle);
                this.setHasPaidNonprotectionBundleExpired(bundle);
                this.setHasFreeNonprotectionBundleExpired(bundle);
                this.setHasMspManagedBundle(bundle);
                this.setHasClBundle(bundle);
            }
        }
    }

    /**
     * Get friendly name of bundle
     * @param app    bundle
     * @returns      friendly name
     */
    getFriendlyName(app) {
        const bundleObj = this.productsConfigService.getBundle(app.bundle_id);

        if (bundleObj) {
            const name = bundleObj.name as IName;
            if (name !== null) {
                if (typeof name !== 'object') {
                    return name;
                }
                if (typeof name === 'object' && name.hasOwnProperty('default')) {
                    const lang = this.languageService.getLang();
                    return name.hasOwnProperty(lang) ? name[lang] : name['default'];
                }
                if (typeof name === 'object' && !name.hasOwnProperty('default') && app.app_params.level) {
                    switch (app.app_params.level) {
                        case AppLevels.PREMIUM:
                            return name.premium;
                        case AppLevels.BASIC:
                            return name.basic;
                        default:
                            break;
                    }
                }
            }
        }
    }

    /**
     * Checks if bundle is box
     * @param bundle
     */
    isBox(bundle: BundleModel) {
        return this.valuesService.boxBundles.has(bundle.bundle_id);
    }

    /**
     * Finds one premium app on the bundle and returns true
     * @param bundle
     */
    isPremiumService(bundle: BundleModel) {
        return this.valuesService.techassistProductsDetails[bundle.bundle_id];
    }

    isVPNSubscription(bundle: BundleModel) {
        return this.valuesService.vpnServices.has(bundle.bundle_id);
    }

    isTSSubscription(bundle: BundleModel) {
        return this.valuesService.tsServices.has(bundle.bundle_id);
    }

    isFPSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleFP;
    }

    isAVSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleAV;
    }

    isISSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleIS;
    }

    isPSSubscription(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundlePSec;
    }

    isVpnPremium(bundle: BundleModel) {
        const vpnApp = bundle.applications.filter(app => app.app_id === this.valuesService.appVPN)[0];

        if (vpnApp) {
            return vpnApp.app_params.level === AppLevels.PREMIUM;
        } else {
            return false;
        }
    }

    /** Checks if bundle is dpy
     * @param bundle
     */
    isDpy(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleDPI;
    }

    /**
     * Check if bundle has zuora source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isZuora(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.zuora
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.zuora;
    }

    /**
     * Check if bundle has google play source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isGooglePlay(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.gplay
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.gplay;
    }

    /**
     * Check if bundle has apple store source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isApple(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.appstore
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.appstore;
    }

    /**
     * Check if bundle has offline source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isOffline(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.offline
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.offline;
    }

    /**
     * Check if bundle has avangate source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isAvangate(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.avangate
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.avangate;
    }

    /**
     * Check if bundle has digital river source type
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {BundleModel} bundle
     * @returns {Boolean}
     */
    public isDigitalRiver(bundle: BundleModel): boolean {
        return bundle?.metadata?.platform_name === this.subscriptionsValuesService.sourceType.digitalRiver
                || bundle?.source?.type === this.subscriptionsValuesService.sourceType.digitalRiver;
    }

    /** Checks if bundle is idTheftProtection
     * @param bundle
     */
     isIdTheftProtection(bundle: BundleModel) {
        return this.valuesService.idTheftProtectionServices.has(bundle.bundle_id);
    }

    isUltimateSecurityProtection(bundle: BundleModel) {
        return this.valuesService.ultimateSecurityProtectionServices.has(bundle.bundle_id);
    }

    /** Checks if bundle is password manager
     * @param bundle
     */
    isPasswordManager(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundlePM;
    }

    /** Checks if bundle is secure identity
     * @param bundle
     */
    isSecureIdentity(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleSecIdentity;
    }

    /**
     * Checks if bundle is hva
     * @param bundle
     */
    isHva(bundle: BundleModel) {
        return bundle.bundle_id === this.valuesService.bundleHVA;
    }

    isSinglePlatformBundle(bundle) {
        return this.valuesService.singlePlatformBundles.has(bundle.bundle_id);
    }

    isMultiPlatformBundle(bundle) {
        return !this.valuesService.singlePlatformBundles.has(bundle.bundle_id);
    }

    setHasSdkLoaded(value) {
        this.subscriptions.hasSdkLoaded = value;
    }

    hasSdkLoaded() {
        return this.subscriptions.hasSdkLoaded;
    }

    /**
     * Account has at least one subscription from Zuora
     */
    setHasZuora() {
        this.subscriptions.hasZuora = !!this.originalSubscriptionsResponse.find(item => this.isZuora(item) && !item?.processed?.sharedInfo?.gainer);
    }

    hasZuora() {
        return this.subscriptions.hasZuora;
    }

    isCountable(appId): number {
        return this.subscriptionsValuesService.uncountableApps.has(appId) ? 0 : 1;
    }

    /**
     * Limits services with unlimited slots to 50 slots
     */
    limitSlots(slots): number {
        return (slots === -1 || slots > this.subscriptionsValuesService.slotsNo.limit) ? this.subscriptionsValuesService.slotsNo.limit : slots;
    }

    filterBundles() {
        const mainPage = {};
        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            mainPage[serviceType] = [];
            for (const bundle of this.subscriptions[serviceType]) {
                if (this.valuesService.knownBundles.has(bundle.bundle_id) && this.showBundleMainPage(bundle)) {
                    mainPage[serviceType].push(bundle);
                }
            }
        }
        this.subscriptions.mainPage = mainPage;
    }

    /**
     * Checks if bundle should be shown in subscriptions main page
     * @param {BundleModel} bundle Bundle object
     * @returns {boolean} True if bundle should be shown, false otherwise
     */
    public showBundleMainPage(bundle: BundleModel): boolean {
        if (bundle.bundle_id === this.valuesService.bundleBIS && this.hasBIP()) {
            return false;
        }
        return true;
    }

    getBundleFromCrc(bundleCrc) {
        if (!bundleCrc) {
            return null;
        }

        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            for (const bundle of this.subscriptions[serviceType]) {
                if (this.valuesService.knownBundles.has(bundle.bundle_id) && bundle?.bundle_crc?.toString() === bundleCrc.toString()) {
                    return bundle;
                }
            }
        }
        return null;
    }

    /**
     * Good god nu inteleg ce vrea functia asta de la viata
     * O sa o lasam asa pt ca mi-e frica
     *
     * Converts bundles from v3 to v4 services
     * @param apps    bundles
     * @return        v4 services
     */
    v3tov4(apps) {
        if (!apps.length || !apps[0].app_id || !apps[0].app_id) {
            return apps;
        }

        const bundles = apps.map( app => app.bundle_id).filter( (item,i,arr)=>arr.indexOf(item) === i);
        const services = [];
        let service:any = {};

        for (let i = 0; i < bundles.length; i++) {
            const tempServices = [];
            let sum_devices = 0;
            for (let j = 0; j < apps.length; j++) {
                if (bundles[i] === apps[j].bundle_id) {
                    if (tempServices.indexOf(apps[j].bundle_id) === -1) {
                        sum_devices += apps[j].active_devices;
                        const friendlyName = this.getFriendlyName(apps[j]);
                        service = {
                            service_id: SubscriptionsV3Prefix?.concat(j?.toString()),
                            service_type: "license",
                            server_time: apps[j].server_time,
                            type: apps[j].type,
                            life_cycle: apps[j].life_cycle,
                            commercial_id: apps[j].commercial_id,
                            commercial_id_info: apps[j].commercial_id_info ? apps[j].commercial_id_info : null,
                            bundle_crc: this.utileCommonService.crc32Convert_old(apps[j].bundle_id),
                            created: apps[j].last_update,
                            end_date: apps[j].expiry,
                            bundle_id: apps[j].bundle_id,
                            bundle_friendly_name: this.productsConfigService.getManufacturerName() + ' ' + friendlyName,
                            friendly_name: friendlyName,
                            slots: this.limitSlots(apps[j].devices),
                            applications: [
                                  {
                                      "countable": this.isCountable(apps[j].app_id),
                                      "slots": {
                                          "min": 1,
                                          "max": apps[j].devices
                                      },
                                      "end_date": apps[j].expiry,
                                      "server_time": apps[j].server_time,
                                      "active_device_ids": apps[j].active_device_ids,
                                      "active_devices" : apps[j].active_devices,
                                      "app_id": apps[j].app_id
                                  }
                            ],
                            status: this.subscriptionsValuesService.status.active,
                            accounts: [
                              {
                                role: "primary",
                                share_id: "V3SHAREID",
                                usage_summary: []
                              }
                            ],
                            active_slots: sum_devices
                          };

                        if (apps[j].hasOwnProperty('ta_quota')) {
                            service.ta_quota = apps[j].ta_quota;
                        }

                        if (apps[j].app_params) {
                            service.applications[0].app_params = apps[j].app_params;
                        }

                    } else {
                        const applications =  {
                            "countable": this.isCountable(apps[j].app_id),
                            "slots": {
                                "min": 1,
                                "max": apps[j].devices
                            },
                            "app_id": apps[j].app_id,
                            "end_date": apps[j].expiry,
                            "server_time": apps[j].server_time,
                            "active_device_ids": apps[j].active_device_ids,
                            "active_devices" : apps[j].active_devices,
                            "app_params":{}
                        };

                        if (apps[j].app_params) {
                            applications.app_params = apps[j].app_params;
                        }


                        service.applications.push(applications);
                    }
                    tempServices.push(apps[j].bundle_id);
                }

                // USAGE SUMMARY:
                if (bundles[i] === apps[j].bundle_id) {
                    for (let k = 0; k < apps[j]?.active_device_ids?.length; k++) {
                        service.accounts[0].usage_summary.push({
                            "app_id": apps[j].app_id,
                            "slot_id": apps[j].active_device_ids[k],
                            "countable": this.isCountable(apps[j].app_id)
                        })
                    }
                }
            }


            if (this.productsConfigService.getBundle(service.bundle_id)) {
                services.push(service);
            }
        }
        return services;
    }

    /**
     * Switch device service
     * @param configObject
     */
    switchDeviceService(configObject): Observable<DeviceAllocationMgmtResponseModel[]> {
        return this.subscriptionMgmtService.switchDeviceService(configObject)
        .pipe(map(resp => resp));
    }

    /**
     * Remove device protection
     * @param configObject
     */
    removeDeviceProtection(configObject):Observable<DeviceAllocationMgmtResponseModel[]> {
        return this.subscriptionMgmtService.removeDeviceProtection(configObject)
        .pipe(map(resp => resp));
    }

    sortAllServices(tempSubscriptions) {
        const isMsp = this.profilesService.hasMspOrXspLevelTwo();
        if (isMsp) {
            this.sortMspBundles(tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription]);
            this.sortMspBundles(tempSubscriptions[this.subscriptionsValuesService.serviceType.license]);
            return;
        }
        this.sortSubscriptions(tempSubscriptions);
        this.sortLicenses(tempSubscriptions);
    }
    /**
     * Sort subscriptions ASC by billing date
     */
    sortSubscriptions(tempSubscriptions) {
        tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription]
        .sort((subsciption1, subscription2) => subsciption1.metadata.billing_date - subscription2.metadata.billing_date);
    }

    /**
     * Sort licenses ASC by end date
     */
    sortLicenses(tempSubscriptions) {
       tempSubscriptions[this.subscriptionsValuesService.serviceType.license]
        .sort((subscription1, subscription2) => (subscription1.end_date ? parseInt(subscription1.end_date) : 0) - (subscription2.end_date ? parseInt(subscription2.end_date) : 0))
        .sort((subscription1, subscription2) => {
                if ((subscription1.type === 'end_user' || subscription1.type === 'trial')
                    && (subscription2.type !== 'end_user' && subscription2.type !== 'trial')) {
                    return -1;
                }
                if ((subscription1.type !== 'end_user' && subscription1.type !== 'trial')
                    && (subscription2.type === 'end_user' || subscription2.type === 'trial')) {
                    return 1;
                }
            }
        );
    }

    sortMspBundles(bundles) {
        bundles.sort((bundle1, bundle2) => {
            const b1 = this.isManagedByOrganization(bundle1);
            const b2 = this.isManagedByOrganization(bundle2);

            if (b1 && !b2) {
                return 1;
            } else if (!b1 && b2) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    /**
     * Show expired services first
     */
    showExpiredLicensesFirst(tempSubscriptions) {
        tempSubscriptions[this.subscriptionsValuesService.serviceType.license]
        .sort(
            (subscription1, subscription2) => {
                if ((subscription1.end_date - subscription1.server_time) <= 0
                    && (subscription2.end_date - subscription2.server_time) >= 0) {
                    return -1;
                }
                if ((subscription2.end_date - subscription2.server_time) <= 0
                    && (subscription1.end_date - subscription1.server_time) >= 0) {
                    return 1;
                }
            }
        );
    }

    removeExpired(resp) {
        return resp.filter(item => !(item.status === this.subscriptionsValuesService.status.expired
                                    && resp.some(elem => elem.bundle_id === item.bundle_id && elem.status === this.subscriptionsValuesService.status.active)));
    }

    removeExpiredFree(resp) {
        return resp.filter(item => !(item.type === this.subscriptionsValuesService.type.free
                                    && item.status === this.subscriptionsValuesService.status.expired));
    }

    /**
     * Filter expired trials - only if there is another service with the same service_id that is not a trial
     */
    removeExpiredTrial(resp) {
        return resp.filter(item => !(item.status === this.subscriptionsValuesService.status.expired
                                    && item.type === this.subscriptionsValuesService.type.trial
                                    && resp.some(elem => elem.bundle_id === item.bundle_id && elem.type !== this.subscriptionsValuesService.type.trial)));
    }

    createTempStructures() {
        const tempSubscriptions = {
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: [], // only active licenses + subscriptions
            filtered: { // filter subscriptions with all slots shared by gainers - so current slots = 0
                [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
                [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
                allServices: [], // filtered licenses + subscriptions
                allActiveServices: [] // only active filtered licenses + subscriptions
            },
            subscriptionsObject: {}, // object key - value: service_id - service object
            hasZuora: false,
            hasPaidCoreBundleExpired: false,
            hasFreeCoreBundleExpired: false,
            hasPaidNonprotectionBundleExpired: false,
            hasFreeNonprotectionBundleExpired: false,
            hasAllBundlesAreExpired: true
        };
        return tempSubscriptions;
    }

    copyArray(subscriptionsArray, subscriptionsObject) {
        const newSubscriptionsArray = [];
        for (const service of subscriptionsArray) {
            const serviceId = service.service_id;
            const newService = subscriptionsObject[serviceId];
            newSubscriptionsArray.push(newService);
        }
        return newSubscriptionsArray;
    }

    copyFromTempToFinalSubscriptions() {
        const newSubscriptions = {
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: [], // only active licenses + subscriptions
            filtered: { // filter subscriptions with all slots shared by gainers - so current slots = 0
                [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
                [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
                allServices: [], // filtered licenses + subscriptions
                allActiveServices: [] // only active filtered licenses + subscriptions
            },
            subscriptionsObject: {}, // object key - value: service_id - service object
            hasZuora: false,
            hasPaidCoreBundleExpired: false,
            hasFreeCoreBundleExpired: false,
            hasPaidNonprotectionBundleExpired: false,
            hasFreeNonprotectionBundleExpired: false,
            hasAllBundlesAreExpired: true
        };

        for (const service of this.tempSubscriptions.allServices) {
            const newService = {...service};
            newSubscriptions.subscriptionsObject[newService.service_id] = newService;
            newSubscriptions.allServices.push(newService);
        }
        this.copyArray(this.tempSubscriptions[this.subscriptionsValuesService.serviceType.license], this.tempSubscriptions);

    }

    retrieveServiceByServiceId(serviceId) {
        return this.subscriptions.subscriptionsObject[serviceId];
    }

    resetFilteredTempStrunctures() {
        this.tempSubscriptions.filtered = { // filter subscriptions with all slots shared by gainers - so current slots = 0
            [this.subscriptionsValuesService.serviceType.license]: [], // filtered licenses
            [this.subscriptionsValuesService.serviceType.subscription]: [], // filtered subscriptions
            allServices: [], // licenses + subscriptions
            allActiveServices: []
        };
    }

    /**
     * List bundles (subscriptions & licenses) (v4 response)
     * @return {Observable<any>} bundles
     */
    list(): Observable<any> {
        if (!this.markToUpdate_subscriptions) {
            return of(this.subscriptions);
        }

        if (this.onlistSubscriptions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSubscriptions$.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.onlistSubscriptions$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.subscriptionMgmtService.list()
            .pipe(
                map(
                    resp => {

                        // creez o structura locala pe care o populez si apoi pun tot rezultatul intr-o structura globala
                        // care memoreaza datele si le mentine pt aplicatie
                        const tempSubscriptions = this.createTempStructures();
                        this.originalSubscriptionsResponse = resp;
                        if (resp.length === 0) {
                            this.subscriptions = tempSubscriptions;
                            this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                            this.markToUpdate_subscriptions = false;
                            return of(this.subscriptions);
                        }

                        resp = this.limitAllSlots(resp);
                        resp = this.v3tov4(resp);
                        resp = this.removeExpiredFree(resp);
                        resp = this.removeExpiredTrial(resp);
                        resp = this.removeExpired(resp);

                        this.populatesubscriptionsStructures(resp, tempSubscriptions);
                        this.sortAllServices(tempSubscriptions);
                        this.showExpiredLicensesFirst(tempSubscriptions);

                        this.subscriptions = tempSubscriptions;
                        this.markToUpdate_subscriptions = false;
                        this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                        return of(this.subscriptions);
                    },
                    err => {
                        this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                        return of(err);
                    }
                ),
                catchError( err => {
                    this.markToUpdate_subscriptions = true;
                    this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                    this.router.navigate([this.valuesService.centralPaths['500'].path]);
                    throw err;
                })
            );
        }
    }

    updateSubscriptions() {
        if (this.onlistSubscriptions$.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.markToUpdate_subscriptions = true;
        }
    }

    limitAllSlots(subscriptions) {
        subscriptions.forEach(bundle => {
            bundle.slots = this.limitSlots(bundle.slots);
        });
        return subscriptions;
    }

    addGeneralSharedSubscriptionsInfo(bundle) {
        const accounts = bundle?.accounts;
        if (!accounts) {
            return;
        }

        let sharedSLots = 0;
        for (const account of accounts) {
            if (account.quota && this.profilesService.getOwnerEmail() !== account.email) {
                sharedSLots += account.quota;
            }

            if (account.quota && this.profilesService.getOwnerEmail() === account.email) {
                bundle.slots = account.quota;
                bundle.processed.allSlotsAreShared = false;
                return;
            }
        }

        bundle.slots -= sharedSLots;
        if (bundle.slots === 0 && sharedSLots) {
            bundle.processed.allSlotsAreShared = true;
        }
    }

    /**
     * Compute shared subscription info
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {nothing}
     */
    private addSharedEntitiesSubscription(bundle: BundleModel): void {
        const sharedInfo = {
            isShared: false,
            payer: null,
            gainer : null,
            isPayer: false,
            gainers: [],
            nrOfGainers: 0
        };

        if (bundle.service_type !== this.subscriptionsValuesService.serviceType.subscription && !this.isEligibleForSharingSubscription(bundle)) {
            bundle.processed.sharedInfo = {};
            return;
        }

        const accounts = bundle?.accounts ?? [];
        for (const account of accounts) {
            if (account.role === this.subscriptionsValuesService.accountType.secondary) {
                sharedInfo.isShared = true;
                sharedInfo.nrOfGainers++;
                if (account.email === this.profilesService.getOwnerEmail()) {
                    sharedInfo.gainer = account;
                }

                sharedInfo.gainers.push({
                    email: account.email,
                    slots: account?.quota,
                    shareId: account?.share_id
                });
            }

            if (account.role === this.subscriptionsValuesService.accountType.primary) {
                if (account.email === this.profilesService.getOwnerEmail()) {
                    sharedInfo.isPayer = true;
                }
                sharedInfo.payer = account;
            }
        }
        bundle.processed.sharedInfo = sharedInfo;
    }

    recomputeActiveSlots(bundle) {
        if (!bundle?.processed?.sharedInfo?.isShared) {
            return;
        }

        const slots = new Set();
        const accounts = bundle?.accounts;

        if (!accounts) {
            bundle.active_slots = 0;
        }

        for (const account of accounts) {
            const usages = account?.usage_summary;
            if (account.email !== this.profilesService.getOwnerEmail() || !usages) {
                continue;
            }

            for (const usage of usages) {
                if (usage?.countable && usage?.slot_id) {
                    slots.add(usage.slot_id);
                }
            }
        }

        bundle.active_slots = Array.from(slots).length;
    }

    addSharedSubscriptionsInfo(bundle) {
        if (!bundle.processed) {
            bundle.processed = {};
        }
        this.addGeneralSharedSubscriptionsInfo(bundle);
        this.addSharedEntitiesSubscription(bundle);

        // recalcularea se face pt ca momentan datele din backend nu sunt consistente
        // se poate scoate daca se rezolva problema pt subscriptiile shared
        this.recomputeActiveSlots(bundle);
    }


    populatesubscriptionsStructures(services, tempSubscriptions) {
        tempSubscriptions.allServices = services;
        for (const service of services) {
            if (service.service_type === this.subscriptionsValuesService.serviceType.license) {
                tempSubscriptions[this.subscriptionsValuesService.serviceType.license].push(service);
            }

            if (service.service_type === this.subscriptionsValuesService.serviceType.subscription) {
                tempSubscriptions[this.subscriptionsValuesService.serviceType.subscription].push(service);
            }

            if (service.status !== this.subscriptionsValuesService.status.expired) {
                tempSubscriptions.allActiveServices.push(service);
            }

            tempSubscriptions.subscriptionsObject[service.service_id] = service;
        }
    }

    populateFilteredSubscriptionsStructures() {
        this.resetFilteredTempStrunctures();
        for (const service of this.subscriptions.allServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allServices.push(service);
            }
        }

        for (const service of this.subscriptions.allActiveServices) {
            if (!service.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered.allActiveServices.push(service);
            }
        }

        for (const license of this.subscriptions[this.subscriptionsValuesService.serviceType.license]) {
            if (!license.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered[this.subscriptionsValuesService.serviceType.license].push(license);
            }
        }

        for (const subscription of this.subscriptions[this.subscriptionsValuesService.serviceType.subscription]) {
            if (!subscription.processed.allSlotsAreShared) {
                this.tempSubscriptions.filtered[this.subscriptionsValuesService.serviceType.subscription].push(subscription);
            }
        }
        this.subscriptions.filtered = this.tempSubscriptions.filtered;
    }

    /**
     * This method is used to get the bundle by serviceId
     * @param {string} serviceId
     * @returns {object} bundle
     */
    getBundleByServiceId(serviceId): BundleModel {
        const services = this.getAllServices();
        return services ? services.filter(bundle => bundle.service_id === serviceId)[0] : null;
    }

    processingMethods = {

        first30Days: (bundle: BundleModel) => {
            let firstMonth;
            const {month, day} = this.subscriptionsValuesService;
            const serverTime        = bundle.server_time;
            const platformStartDate = this.usefulService.getNested(bundle, undefined, 'metadata', 'platform_sub_start_date');
            const billingDate       = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_date');
            const billingCycle      = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_cycle');

            if (platformStartDate) {
                firstMonth = serverTime - platformStartDate <= month;
            } else if (billingDate && billingCycle) {
                firstMonth = serverTime - (billingDate - billingCycle * day) <= month;
            }

            bundle.processed.firstMonth = firstMonth;
        },

        isExtended: (bundle: BundleModel) => {
            const billingDate  = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_date');
            const billingCycle = this.usefulService.getNested(bundle, undefined, 'metadata', 'billing_cycle');
            if (billingDate && billingCycle) {
                const createdMoment = moment.unix(bundle.created).format('YYYY-MM-DD');
                const billingMoment = moment.unix(billingDate).format('YYYY-MM-DD');
                const billingCycleDays = moment(billingMoment).diff(moment(createdMoment), 'days');
                bundle.processed.isExtended = billingCycle < billingCycleDays - 1;
            }
        },

        isMonthlyBundle: (bundle: BundleModel) => {
            bundle.processed.isMonthly = bundle?.metadata?.billing_period === this.subscriptionsValuesService.cycles.monthlyBillingPeriod
                                        || bundle?.metadata?.billing_cycle === this.subscriptionsValuesService.monthDays;
        }
    };

    /**
     * Get bundles (subscriptions & licenses) (v4 response)
     */
    get() {
        return this.subscriptions;
    }

    getMainPageBundles() {
        return this.subscriptions.mainPage;
    }

     /**
     * Get bundles (subscriptions & licenses) (v4 response)
     */
      getFiltered() {
        return this.subscriptions.filtered;
    }

    /**
     * Get bundles (v4 response)
     */
    getAllServices() {
        return this.subscriptions.allServices;
    }

    getAllFilteredServices() {
        return this.subscriptions.filtered.allServices;
    }

    getAllActiveServices() {
        return this.subscriptions.allServices.filter(service => service.status === this.subscriptionsValuesService.status.active);
    }

    getAllFilteredActiveServices() {
        return this.subscriptions.filtered.allServices.filter(service => service.status === this.subscriptionsValuesService.status.active);
    }

    getBoxSubscriptionStatus(boxId: string) {
        for (const bundle of this.subscriptions.allServices) {
            if (!this.valuesService.boxBundles.has(bundle.bundle_id) || !bundle?.accounts) {
                continue;
            }

            for (const account of bundle.accounts) {
                if (this.profilesService.getOwnerEmail() !== account.email) {
                    continue;
                }
                if (account?.usage_summary) {
                    for (const usage of account?.usage_summary) {
                        if (this.valuesService.boxApps.has(usage?.app_id) && usage?.slot_id === boxId) {
                            return bundle.status;
                        }
                    }
                }
            }

        }
        return undefined;
    }

    /**
     * Get subscriptions
     */
    getSubscriptions() {
        return this.subscriptions[this.subscriptionsValuesService.serviceType.subscription];
    }

    /**
     * Get licenses
     */
    getLicenses() {
        return this.subscriptions[this.subscriptionsValuesService.serviceType.license];
    }

    getCurrency() {
        let currency;
        const defaultCurrency = this.subscriptionsValuesService.defaultCurrency;
        const subscriptions = this.getSubscriptions();
        if (subscriptions.length) {
            const subsWithCurrency = subscriptions.filter(item => item.metadata && item.metadata.platform_currency);
            currency = subsWithCurrency.length ? subsWithCurrency[0].metadata.platform_currency : defaultCurrency;
        } else {
            currency = defaultCurrency;
        }
        return currency;
    }

    /**
     * Returns all services with status "ACTIVE" & type "end_user"
     */
    getPaidServices() {
        // toate serviciile platite de ownerul contului
        // daca ownerul e gainer, nu plateste serviciul respectiv
        const paidServices = [];
        for (const service of this.subscriptions.allServices) {
            if (service.status === this.subscriptionsValuesService.status.active && service.type === 'end_user' && !service?.processed?.sharedInfo?.gainer) {
                paidServices.push(service);
            }
        }
        return paidServices;
    }

    /**
     * Get all security single platform services
     */
    getSecuritySinglePlatformServices() {
        const singlePlatformServices = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.isSinglePlatformSubscription && bundle.type !== this.subscriptionsValuesService.type.nfr &&
                bundle.type !== this.subscriptionsValuesService.type.free && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    singlePlatformServices.push(bundle);
            }
        }
        return singlePlatformServices;
    }

    /**
     * Get all security single platform services
     */
    getSecurityMultiPlatformServices() {
        const multiPlatformServices = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.isMultiPlatformSubscription && bundle.type !== this.subscriptionsValuesService.type.nfr &&
                bundle.type !== this.subscriptionsValuesService.type.free && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    multiPlatformServices.push(bundle);
            }
        }
        return multiPlatformServices;
    }

    /*
    * Get all security bundles
    */
   getSecurityBundles() {
       const bundlesArr = [];
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type !== this.subscriptionsValuesService.type.nfr && bundle.type !== this.subscriptionsValuesService.type.free &&
                this.valuesService.securityBundles.has(bundle.bundle_id)) {
                    bundlesArr.push(bundle);
            }
        }
        return bundlesArr;
   }

    /**
     * Get bundle image
     */
    getImage = function(bundle) {
        const lang = this.profilesService.getOwner().lang;
        const bundleObj = this.productsConfigService.getBundle(bundle.bundle_id);

        if (bundleObj) {
            let pic = bundleObj['pic'];
            if (pic) {
                switch (typeof pic) {
                    case 'string': {
                        break;
                    }
                    case 'object': {
                        pic = pic.hasOwnProperty(lang) ? pic[lang] : pic['default'];
                        break;
                    }
                    default: {
                        return '';
                    }
                }
            }
            return pic;
        } else {
            return '';
        }
    };

    daysRemaining(bundle) {
        let result;
        if (bundle.end_date) {
            result = parseInt(((bundle.end_date - bundle.server_time) / this.valuesService.SECONDS_IN_A_DAY).toString());
        }
        return result;
    }

    /**
     * Returns bundles with status !== 'EXPIRED'
     */
    getActiveBundles() {
        return this.subscriptions.allActiveServices;
    }

    getFilteredActiveBundles() {
        return this.subscriptions.filtered.allActiveServices;
    }

    /**
     * Returns an array with commercial ids
     */
    getCommercialIds() {
        // daca ownerul e gainer, nu plateste serviciul respectiv
        // deci nu are rost sa ii afisam oferte referitoare la acest serviciu
        const commercialIds = new Set();
        for (const service of this.subscriptions.allServices) {
            if (service.commercial_id && !service?.processed?.sharedInfo?.gainer) {
                commercialIds.add(service.commercial_id);
            }
        }
        return Array.from(commercialIds);
    }

    /**
     * Computes the number of active slots, removing all the slots occupied by boxes from the count
     * @param bundle Bundle object
     * @returns Number of active slots
     */
     getActiveDevicesWithoutBox(bundle) {
        const boxDeviceIds = [];
        if (bundle.accounts) {
            bundle.accounts.forEach(account => {
                if (account.email === this.profilesService.getOwnerEmail() && account?.usage_summary) {
                    account.usage_summary.forEach(usage => {
                        // if is countable, so is counted inside active_slots property
                        // if slot app is a box app
                        // and if slot id is unique
                        // add it to the array that need to be subtracted from the total numebr of active_slots
                        if (usage.slot_id && usage.countable === 1 && this.valuesService.boxApps.has(usage.app_id) && boxDeviceIds.indexOf(usage.slot_id) === -1) {
                            boxDeviceIds.push(usage.slot_id);
                        }
                    });
                }
            });
        }

        if (bundle.active_slots && bundle.active_slots > boxDeviceIds.length) {
            return bundle.active_slots - boxDeviceIds.length;
        } else {
            return 0;
        }
    }

    hasPaidSubscription(): boolean {
        return !!this.subscriptions.hasPaidSubscription;
    }

    hasTrialSubscription(): boolean {
        return !!this.subscriptions.hasTrialSubscription;
    }

    hasParental(): boolean {
        return !!this.subscriptions.hasParental;
    }

    hasParentalNCCPremium(): boolean {
        return !!this.subscriptions.hasParentalNCCPremium;
    }

    hasParentalNCCBasic(): boolean {
        return !!this.subscriptions.hasParentalNCCBasic;
    }

    hasParentalNCC(): boolean {
        return this.hasParentalNCCPremium() || this.hasParentalNCCBasic();
    }

    hasClSubscriptions(): boolean {
        return !!this.subscriptions.hasClSubscription;
    }

    hasDataPrivacy(): boolean {
        return !!this.subscriptions.hasDataPrivacy;
    }

    hasIdTheftProtection(): boolean {
        return !!this.subscriptions.hasIdTheftProtection;
    }

     /**
     * Gets 'hasWebmailProtection' subscription flag
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {boolean} is user has webmail protection
     */
    public hasWebmailProtection(): boolean {
        return this.subscriptions.hasWebmailProtection;
    }

    /**
     * Gets the expired Data Privacy subscription flag
     * @returns true if the user has only expired Data Privacy subscriptions
     */
    hasDataPrivacyExpired() {
        return this.subscriptions.hasDataPrivacyExpired && !this.subscriptions.hasDataPrivacy;
    }

    hasTSMD(): boolean {
        return !!this.subscriptions.hasTSMD;
    }

    hasTSMDExpired(): boolean {
        return !!this.subscriptions.hasTSMDExpired;
    }

    hasBox(): boolean {
        return !!this.subscriptions.hasBox;
    }

    hasBoxExpired(): boolean {
        return !!this.subscriptions.hasBoxExpired;
    }

    hasBox2(): boolean {
        return !!this.subscriptions.hasBox2;
    }

    hasBox2Expired(): boolean {
        return !!this.subscriptions.hasBox2Expired;
    }

    hasVpn(): boolean {
        return !!this.subscriptions.hasVpn;
    }

    hasPremiumVpn(): boolean {
        return !!this.subscriptions.hasPremiumVpn;
    }

    hasPasswordManagerExpired(): boolean {
        return !!this.subscriptions.hasPasswordManagerExpired;
    }

    hasPasswordManagerTrialExpired(): boolean {
        return this.subscriptions.hasPasswordManagerTrialExpired === true;
    }

    hasPasswordManager(): boolean {
        return !!this.subscriptions.hasPasswordManager;
    }

    hasPasswordManagerCompatible(): boolean {
        return !!(this.subscriptions.hasPasswordManagerThreeMonthsCompatible || this.subscriptions.hasPasswordManagerOneMonthCompatible);
    }

    hasPasswordManagerThreeMonthsCompatible(): boolean {
        return !!this.subscriptions.hasPasswordManagerThreeMonthsCompatible;
    }

    hasPasswordManagerOneMonthCompatible(): boolean {
        return !!this.subscriptions.hasPasswordManagerOneMonthCompatible;
    }

    /**
     * Check if a password manager subscription exists or if a compatible subscriptions exists
     * @returns {boolean} true if a password manager subscription exists or if a compatible subscriptions exists
     */
    public hasPasswordManagerOrCompatibleBundle(): boolean {
        return !!(this.hasPasswordManager() || this.hasPasswordManagerCompatible());
    }

    /**
     * Check if an expired password manager subscription exists or if a trial subscriptions exists
     * @returns {boolean} true if an expired password manager subscription exists or if a trial subscriptions exists
     */
    public hasExpiredPasswordManagerOrTrial(): boolean {
        return !!(this.hasPasswordManagerExpired() || this.hasPasswordManagerTrialExpired());
    }

    /**
     * Check if managed msp subscription exists
     * @public
     * @memberof SubscriptionMgmtService
     * @returns {boolean} The value for 'hasMspManagedBundle' flag
     */
    public hasMspManagedBundle(): boolean {
        return !!this.subscriptions.hasMspManagedBundle;
    }

    /**
     * Check if user has shared subscription
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {none}
     * @returns {Boolean}
     */
    public hasSharedSubscription(): boolean {
        return !!this.subscriptions.hasSharedSubscription;
    }

    /**
     * Check if premium service subscription exists
     * @param {nothing}
     * @returns {boolean} representing the value for 'hasPremiumServices' flag
     */
    hasPremiumServices(): boolean {
        return !!this.subscriptions.hasPremiumServices;
    }

    hasPremiumSecPlus(): boolean {
        return !!this.subscriptions.hasPremiumSecPlus;
    }

    hasOnlyIdentitySubscriptions(): boolean {
        return this.subscriptions.hasOnlyIdentitySubscriptions !== false;
    }

    hasOnlySohoSubscriptions(): boolean {
        return this.subscriptions.hasOnlySohoSubscriptions !== false;
    }

    hasSohoSubscription(): boolean {
        return !!this.subscriptions.hasSohoSubscription;
    }

    hasSohoSubscriptionExpired(): boolean {
        return !!this.subscriptions.hasSohoSubscriptionExpired;
    }

    hasNoSubscriptions(): boolean {
        return this.getAllServices().length === 0;
    }

    hasNoActiveSubscriptions(): boolean {
        return this.getAllActiveServices().length === 0;
    }

    /**
     * Returns 'max_shares' value for given bundle
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {number}
     */
    public getBundleMaxShares(bundle: BundleModel): number {
        let sharesNo = 0;

        if (bundle.hasOwnProperty('max_shares')) {
            sharesNo = bundle.max_shares;
        }
        return sharesNo;
    }

    /**
     * Checks eligibility for sharing the subscription based on bundleId, bundle type and 'max_shares' param
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {boolean}
     */
    public isEligibleForSharingSubscription(bundle: BundleModel): boolean {
        const maxSharesNo = this.getBundleMaxShares(bundle);
        let isEligible = false;

        if (this.valuesService.bundlesEligibleForShareSubscription.has(bundle.bundle_id) && maxSharesNo > 0 && bundle.type === this.subscriptionsValuesService.type.end_user) {
            isEligible = true;
        }
        return isEligible;
    }

    /**
     * Check if user is payer on the shared subscription
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {Boolean}
     */
    public isSubscriptionPayer(bundle: BundleModel): boolean {
        return !!bundle?.processed?.sharedInfo?.isPayer;
    }

    /**
     * Check if user is gainer on the shared subscription
     *
     * @public
     * @memberof SubscriptionMgmtService
     * @param {Object} bundle
     * @returns {Boolean}
     */
    public isSubscriptionGainer(bundle: BundleModel): boolean {
        return !!bundle?.processed?.sharedInfo?.gainer;
    }

    hasAutoRenewalOn() {
        return !!this.subscriptions.hasAutoRenewalOn;
    }

    hasPaidSecurityBundles(): boolean {
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type === this.subscriptionsValuesService.type.end_user
                && bundle.status === this.subscriptionsValuesService.status.active
                && this.valuesService.securityBundles.has(bundle.bundle_id)) {
                return true;
            }
        }
        return false;
    }

    hasOnlyTrialSecurityBundles(): boolean {
        for (const bundle of this.getActiveBundles()) {
            if (bundle.type === this.subscriptionsValuesService.type.trial && !this.valuesService.securityBundles.has(bundle.bundle_id)) {
                return false;
            }
        }
        return true;
    }

    hasVpnRecomCard(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (this.valuesService.vpnApps.has(app.app_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    hasPremiumVpnRecomCard(): boolean {
        for (const bundle of this.getActiveBundles()) {
            for (const app of bundle.applications) {
                if (this.valuesService.vpnApps.has(app.app_id) && app?.app_params?.level === AppLevels.PREMIUM) {
                    return true;
                }
            }
        }
        return false;
    }

    getDevicesForVpn() {
        const servicesList = this.getAllFilteredServices();
        const serviceInfos = [];
        let found = false;

        for (const service of servicesList) {
            const serviceInfo = {
                allSlots: 0,
                unlimited: false,
                created: 0,
                slotIds: [],
                bundleId: '',
                serviceId: ''
            };

            found = false;
            if (service.status !== this.subscriptionsValuesService.status.active) {
                continue;
            }

            for (const app of service.applications) {
                if (app.app_id === this.valuesService.appVPN) {
                    serviceInfo.allSlots = app.slots.max;
                    if (app.slots.max === -1) {
                        // iau un numar maxim de 1000 de devices
                        serviceInfo.allSlots = 1000;
                        serviceInfo.unlimited = true;
                    }
                    found = true;
                }
            }

            if (found) {
                serviceInfo.bundleId = service.bundle_id;
                serviceInfo.created = service.created;
                serviceInfo.serviceId = service.service_id;
                serviceInfo.slotIds = this.getSlotIds(service);
                serviceInfos.push(serviceInfo);
            }
        }
        return serviceInfos;
    }

    getSlotIds(service) {
        const slotIds = [];
        const accounts = service.accounts;
        if (!accounts) {
            return slotIds;
        }

        for (const account of accounts) {
            const usageSummary = account.usage_summary;
            for (const summary of usageSummary) {
                if (summary.app_id === this.valuesService.appVPN) {
                    slotIds.push(summary.slot_id);
                }
            }
        }
        return slotIds;
    }

    // incearca sa gaseasca un serviciu >= cu cel curent, care are sloturi libere
    // daca se gaseste acest serviciu, inseamna ca nu avem limit reached pt serviuciul dat ca parametru
    // deoarece avem un alt serviciu cu sloturi libere care indeplineste aceleasi sarcini si userul poate sa adauge acolo device-uri
    hasSimilarOrSuperiorIncompleteService(bundle): boolean {
        const services = this.getAllFilteredActiveServices();

        for (const batchOrder of this.valuesService.orderedServices) {
            const currentBundleRank = batchOrder.indexOf(bundle.bundle_id);

            for (const service of services) {
                // daca serviciul  nu e activ sau e full, conitnuam cautarea
                if (service.status !== this.subscriptionsValuesService.status.active || this.allSlotsAreFull(service)) {
                    continue;
                }

                // cautam servicii identice cu sloturi goale
                if (service.bundle_id === bundle.bundle_id && !this.allSlotsAreFull(service)) {
                    return true;
                }

                // cautam daca avem un serviciu superior cu sloturi goale
                const otherBundleRank = batchOrder.indexOf(service.bundle_id);
                if (currentBundleRank !== -1 && otherBundleRank >= currentBundleRank) {
                    return true;
                }
            }
        }

        return false;
    }

    checkForLimitReachedBannerAppearance() {
        const services = this.getAllFilteredActiveServices();
        for (const service of services) {
            if (this.showBuyMoreProtectionLink(service)) {
                return true;
            }
        }
        return false;
    }

    getHighestLimitReachedService() {
        let maxPriority = -1;
        let maxBundle = null;
        const services = this.getAllFilteredActiveServices();

        for (const bundle of services) {
            if (this.showBuyMoreProtectionLink(bundle)) {
                const priority = this.valuesService.orderedServicesFirstRecommendationCard.indexOf(bundle.bundle_id);
                if (priority > maxPriority) {
                    maxBundle = bundle;
                    maxPriority = priority;
                }
            }
        }
        return maxBundle;
    }

    allSlotsAreFull(bundle: BundleModel) {
        return bundle.interface.devicesNoBox === bundle.slots;
    }

    showBuyMoreProtectionLink(bundle: BundleModel) {
        const hasMsporXsp = this.profilesService.hasMspOrXspLevelOne();

        // momentan pt subscriptiile shared nu afisam buy more protection/ buy more slots
        if (bundle?.processed?.sharedInfo?.isShared) {
            return false;
        }

        // Will only be shown for specific bundles
        if (!this.valuesService.bundlesWithProtectMoreDevices.has(bundle.bundle_id)) {
            return false;
        }

        // "Buy more slots" won't be shown for msp level 1 or soho
        if (hasMsporXsp || this.hasSohoSubscription()) {
            return false;
        }

        // "Buy more slots" won't be shown if bundle is other than active (expired or cancelled)
        if (bundle.status !== this.subscriptionsValuesService.status.active) {
            return false;
        }

        // "Buy more slots" won't be shown for life_cycle = lifetime or type = free, trial, nfr
        if (bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime || this.valuesService.typesWithoutProtectMoreDevices.has(bundle.type)) {
            return false;
        }

        // "Buy more slots" will not be displayed if some slots are still empty
        if (!this.allSlotsAreFull(bundle)) {
            return false;
        }

        // "Buy more slots" will not be displayed if superior or similar services has empty slots
        if (this.hasSimilarOrSuperiorIncompleteService(bundle)) {
            return false;
        }

        return true;
    }

    hideEndDate(bundle) {
        // for Softbank
        return this.valuesService.boxBundles.has(bundle.bundle_id) && bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.recurrent;
    }

    getServiceToBeReallocated(bundle) {
        let serviceToBeReallocated = null;
        if (this.valuesService.bundlesThatReallocateDevices.has(bundle.bundle_id)) {
            let maxPriority = -1;

            for (const service of this.getAllActiveServices()) {
                const priority = this.valuesService.bundlesWhoseDevicesAreReallocated.indexOf(service.bundle_id);

                if (bundle.bundle_id !== service.bundle_id
                    && priority > -1
                    && bundle.slots >= service.slots
                    && service.active_slots >= 1
                    && service.life_cycle !== this.subscriptionsValuesService.lifeCycle.lifetime) {

                    // User has several AV/IS bundles -> choose the one with the fewest days left
                    if (maxPriority === priority && service.end_date < serviceToBeReallocated?.end_date) {
                        serviceToBeReallocated = service;
                    }
                    if (priority > maxPriority) {
                        maxPriority = priority;
                        serviceToBeReallocated = service;
                    }
                }
            }
        }
        return serviceToBeReallocated;
    }

    /**
     * It is similar to the "createDevicesInfoAllocationInterface" from isubscriptions
     * It is used for a similar purpose and a similar flow
     * @param bundle
     * @returns
     */
    getAppsForEveryDevice(bundle) {
        const apps = {};
        const devices = Array.isArray(bundle?.interface?.devices) ? bundle.interface.devices : [];
        for (const deviceId of devices) {
            const appArr = [];
            const accounts = Array.isArray(bundle?.accounts) ? bundle.accounts : [];
            for (const account of accounts) {
                const usageSummary = Array.isArray(account?.usage_summary) ? account.usage_summary : [];
                for (const usage of usageSummary) {
                    if (usage.slot_id === deviceId) {
                        appArr.push(usage.app_id);
                    }
                }
            }
            apps[deviceId] = {
                appIds: appArr
            };
        }
        return apps;
    }

}
