// External
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

// Internal
import { DevicesService } from '../../process/devices/devices.service';
import { SubscriptionsService } from '../../process/subscriptions/subscriptions.service';
import { ValuesService } from '../../../../common/values/values.service';
import { LanguageService } from '../../core/language.service';
import { SubscriptionInviteStatus, SubscriptionsValuesService } from '../../../values/subscriptions.values.service';
import { SubscriptionsHelperService } from '../../../../pages/subscriptions/subscriptions.helper.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { InvoicesService } from '../../process/subscriptions/invoices.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ProductsConfigService } from '../../../../common/config/products.config.service';
import { CampaignMediaIdentifiers } from '../../core/AdobeParams.model';
import { BuyLinksService } from '../../../../common/links/buyLinks.service';
import { BundleModel, DeviceAllocationDisplayComponents } from '../../../../common/models/Services.model';
import { InvitesService } from '../../process/subscriptions/invites.service';

export interface InstallModalAppInterface {
    occupiedSlots: number;
    totalSlots: number;
}

interface InstallModalAppsInterface {
    [appIdLevel: string]: InstallModalAppInterface
}

export const NO_LEVEL = 'no_level';
export const ALL_PLATFORMS = 'all_platforms';

@Injectable({
    providedIn: 'root'
})

export class ISubscriptionsService {

    private installModalAppsInterface: InstallModalAppsInterface = {};

    constructor(
        private readonly devicesService: DevicesService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly translate: TranslateService,
        private readonly languageService: LanguageService,
        private readonly valuesService: ValuesService,
        private readonly translateService: TranslateService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly subscriptionsHelperService: SubscriptionsHelperService,
        private readonly connectMgmtService: ConnectMgmtService,
        private readonly productsConfigService: ProductsConfigService,
        private readonly profilesService: ProfilesService,
        private readonly invoicesService: InvoicesService,
        private readonly appsConfigService: AppsConfigService,
        private readonly buyLinksService: BuyLinksService,
        private readonly invitesService: InvitesService
    ) {}

    /**
     * Get bundle image
     * For compatibily reasons isubscriptions.getImage is used in more then one place still. should include in refactor.
     */
    getImage = function(bundle) {
        return  this.subscriptionsService.getImage(bundle);
    };

    /**
     * Get bundle level (bundle_id & app_id must be the same for bundle with basic/premium levels in values)
     */
    getLevel = function(bundle): string {
        let level;
        bundle.applications.forEach(app => {
            if (app.hasOwnProperty('app_params')) {
                if (app.app_params.hasOwnProperty('level')) {
                    level = app.app_params.level;
                    return;
                }
            }
        });
        return level;
    };

    /**
     * Get bundle description
     */
    getDescription(bundle, typeOfDescription: string) {
        let desc: any, type: string;
        const level = this.getLevel(bundle);
        const bundleObj = this.productsConfigService.getBundle(bundle.bundle_id);

        if (bundleObj) {
            type = typeOfDescription === 'maindescription' ? 'mainDesc' : 'desc';
            desc = bundleObj[type];

            if (desc && typeof desc === 'object') {
                desc = desc[level];
            }
        }

        return desc;
    }

    /**
     * Computes the array of device ids that occupy slots on the bundle
     * @param bundle Bundle object
     * @returns An array of device ids
     */
    getActiveDevices(bundle) {
        const devices = [];
        if (!bundle?.accounts) {
            return devices;
        }

        bundle.accounts.forEach(account => {
            if (account?.usage_summary) {
                account.usage_summary.forEach(usage => {
                    if (usage.slot_id && usage.countable === 1 && devices.indexOf(usage.slot_id) === -1 && !this.valuesService.boxApps.has(usage.app_id)) {
                        devices.push(usage.slot_id);
                    }
                });
            }
        });
        return devices;
    }

    daysRemaining(bundle) {
        let result;
        if (bundle.end_date) {
            result = Math.round((bundle.end_date - bundle.server_time) / this.valuesService.SECONDS_IN_A_DAY);
        }
        return result;
    }

    computeBillingCycle(bundle) {
        let result;
        const billingCycle  = bundle?.metadata?.billing_cycle;
        const billingPeriod = bundle?.metadata?.billing_period;

        if (bundle.metadata && billingCycle) {
            // Conditie temporara in cazul subscriptiilor monthly - pana se repara in checkout
            if ((billingPeriod && billingPeriod === this.subscriptionsValuesService.cycles.monthlyBillingPeriod) || billingCycle === this.subscriptionsValuesService.monthDays) {
                result = this.translate.instant('subscriptions.service.monthly');
            }

            if ((!result && billingCycle === this.subscriptionsValuesService.year)
                || (billingPeriod && billingPeriod === this.subscriptionsValuesService.cycles.yearlyBillingPeriod)) {
                result = this.translate.instant('subscriptions.service.yearly');
            }

            if (billingCycle === this.subscriptionsValuesService.biennal) {
                result = this.translate.instant('subscriptions.service.biennial');
            }


            if (billingCycle === this.subscriptionsValuesService.triennal) {
                result = this.translate.instant('subscriptions.service.triennial');
            }
        }
        return result;
    }

    // Creates interface for < /subscriptions >. Property interface is attached to _subscriptions.bundles
    createBundlesInterface() {
        const tempInstallModalAppsInterface: InstallModalAppsInterface = {};
        const lang = this.languageService.getLang();
        for (const serviceType in this.subscriptionsValuesService.serviceType) {
            for (const bundle of this.subscriptionsService.get()[serviceType]) {
                const endDate = bundle.end_date ? bundle.end_date * 1000 : 0;
                const renewalDate = bundle.metadata && bundle.metadata.billing_date ? bundle.metadata.billing_date : bundle.end_date;
                moment.locale(lang.split('_')[0]);

                const bundleInterface = {
                    name:               bundle.bundle_friendly_name,
                    renewalDate:        renewalDate,
                    price:              bundle.metadata && bundle.metadata.billing_cycle_price ? bundle.metadata.billing_cycle_price : 0,
                    currency:           bundle.metadata && bundle.metadata.currency ? bundle.metadata.currency : 'no currency' ,
                    daysRemaining:      this.daysRemaining(bundle),
                    devices:            this.getActiveDevices(bundle),
                    devicesNoBox:       this.subscriptionsService.getActiveDevicesWithoutBox(bundle),
                    expired:            bundle.status === "EXPIRED" ? true : false,
                    image:              this.subscriptionsService.getImage(bundle),
                    description:        this.getDescription(bundle, 'description'),
                    mainDesc:           this.getDescription(bundle, 'maindescription'),
                    type:               bundle.type,
                    serviceTypeLocal:   bundle.service_type === this.subscriptionsValuesService.serviceType.license
                                        ? 'subscription.type.license'
                                        : 'subscription.type.subscription',
                    billing_cycle:      this.computeBillingCycle(bundle),
                    end_date:           endDate,
                    level:              this.getLevel(bundle),
                    hideEndDate:        this.subscriptionsService.hideEndDate(bundle),
                    owner:              this.subscriptionsHelperService.getSubscriptionsOwner(bundle),
                    endDateLocale:      moment(endDate).format('LL'),
                    renewalDateLocale:  moment(renewalDate * 1000).format('LL'),
                    billingDateLocale:  bundle.metadata && bundle.metadata.billing_date ? moment(bundle.metadata.billing_date * 1000).format('LL') : '',

                    startPlan:          bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.startPlan,
                    personalPlan:       bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.personalPlan,
                    familyPlan:         bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.familyPlan,
                    ultimatePlan:       bundle.plan_name && bundle.plan_name === this.subscriptionsValuesService.plans.ultimatePlan
                };
                bundle.interface = bundleInterface;
                // links
                bundle.interface.install        = this.computeInstallInterface(bundle);
                bundle.interface.invites        = this.createInvitesInterface(bundle);
                bundle.interface.badge          = this.createBadgeInterface(bundle);
                bundle.interface.buttons        = this.createDropdownManageButtonsInterface(bundle);
                bundle.interface.expiryAndUsage = this.computeExpiryAndUsageInterface(bundle);
                bundle.isSinglePlatformSubscription = this.subscriptionsService.isSinglePlatformBundle(bundle);
                bundle.isMultiPlatformSubscription  = this.subscriptionsService.isMultiPlatformBundle(bundle);

                this.createApplicationsInterface(bundle, tempInstallModalAppsInterface);
            }
        }

        this.installModalAppsInterface = tempInstallModalAppsInterface;
    }

    createServiceDetailsInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        // TODO to be changed when convert is shown
        // bundle.interface.showConvert = this.showConvert(bundle);

        bundle.interface.showConvert = false;
        bundle.interface.showBuyMoreSlotsLink = this.subscriptionsService.showBuyMoreProtectionLink(bundle);
    }

    createServiceDetailsModalInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        const details = {
            applications: bundle.applications.filter(item => !this.valuesService.appsNotShownServiceDetails.has(item.app_id)),
            singleAppBundleFeatures: this.getSingleAppBundleFeatures(bundle)
        };
        bundle.interface.details = details;
    }

    getSingleAppBundleFeatures(bundle) {
        let features = [];
        if (this.valuesService.singleAppBundlesFeatures.hasOwnProperty(bundle.bundle_id)) {
            features = this.valuesService.singleAppBundlesFeatures[bundle.bundle_id];
        }
        return features;
    }

    showConvert(bundle) {
        const isZuora           = this.subscriptionsService.hasZuora() || this.invoicesService.hasInvoicesCookie();
        const isSubscription    = bundle.service_type === this.subscriptionsValuesService.serviceType.license;
        const expired           = bundle?.interface?.expired;
        const twoMonthsDays     = bundle?.interface?.daysRemaining
                                    && bundle.interface.daysRemaining <= this.subscriptionsValuesService.twoMonthsDays;
        const availableSlots    = this.subscriptionsValuesService.availableForConversionBundleSlots[bundle.bundle_id]?.has(bundle.slots);

        return isZuora && isSubscription && (twoMonthsDays || expired) && availableSlots;
    }

    // arr(auto renewal) off - adica fie reactivezi auto renewal (zuora), fie apare butonul de renew/buy
    // arr(auto renewal) on - adica fie dezactivezi auto renewal (zuora), fie nu faci nimic pt ca nu ai nimic de facut (nu apare renew ca se face automat renew)
    createDropdownManageButtonsInterface(bundle) {
        let arrON = false;

        const isEligibleForSharingSub = this.subscriptionsService.isEligibleForSharingSubscription(bundle);
        const canHaveRenewLink  = !this.valuesService.bundlesWithoutRenewLink.has(bundle.bundle_id);
        const buttonsInterface = {
            manageButtons: [this.subscriptionsValuesService.manageButtons.details],
            arrOFFButton: null,
            buyOrRenew: {
                link: '',
                cta: '',
                description: ''
            }
        };

        // Used for shorter conditions
        const check = {
            // source type
            isZuora:        this.subscriptionsService.isZuora(bundle),
            isGooglePlay:   this.subscriptionsService.isGooglePlay(bundle),
            isApple:        this.subscriptionsService.isApple(bundle),
            isOffline:      this.subscriptionsService.isOffline(bundle),
            // service type
            isLicense:      bundle.service_type === this.subscriptionsValuesService.serviceType.license,
            // life cycle
            isActive:       bundle.status === this.subscriptionsValuesService.status.active,
            isLifetime:     bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime
                            && bundle.interface.devices.length > 0,
            isDpy:          this.subscriptionsService.isDpy(bundle),
            isIDTP:         this.subscriptionsService.isIdTheftProtection(bundle),
            isPM:           this.subscriptionsService.isPasswordManager(bundle),
            isHva:          this.subscriptionsService.isHva(bundle),
            isTrial:        bundle.type === this.subscriptionsValuesService.type.trial,
            isRenewableDpy: this.subscriptionsService.isDpy(bundle)
                            && bundle.end_date
                            && bundle?.metadata?.billing_cycle,
            isExpired:      bundle?.status === this.subscriptionsValuesService.status.expired,
            isPayer:        this.subscriptionsService.isSubscriptionPayer(bundle),
            isGainer:       this.subscriptionsService.isSubscriptionGainer(bundle),
            hasNoInvites:   bundle?.interface?.invites?.availableInvites === bundle?.interface?.invites?.manageInvites?.length,
            hasActiveSlots: bundle.interface.devicesNoBox,
            lastMonth:      bundle.interface.daysRemaining !== undefined
                            && bundle.interface.daysRemaining <= this.subscriptionsValuesService.monthDays
        };

        if (!check.isDpy
            && !check.isIDTP
            && !check.isHva
            && !check.isPM
            && check.hasActiveSlots
            && bundle.status === this.subscriptionsValuesService.status.active
            && !check.isLifetime) {
            buttonsInterface.manageButtons.push(this.subscriptionsValuesService.manageButtons.activeSlots);
        }

        if (!check.isGainer
            && check.isActive
            && !this.profilesService.hasMspOrXspLevelOne()
            && bundle.processed.arrON
            && this.appsConfigService.showDployFlowForBundle(bundle)) {
            if (check.isZuora) {
                buttonsInterface.manageButtons.push(this.subscriptionsValuesService.manageButtons.autoRenewON);
            }
            this.subscriptionsService.setHasAutoRenewalOn();
            arrON = true;
        }

        if (!check.isGainer
            && !arrON
            && !this.profilesService.hasMspOrXspLevelOne()
            && bundle.processed.arrOFF
            && check.isZuora) {
                buttonsInterface.arrOFFButton = this.subscriptionsValuesService.manageButtons.autoRenewOFF[bundle.status];
        }

        if (!check.isGainer
            && (this.bundleHasInstallButton(bundle) || bundle?.interface?.install?.showBuyOrRenew)
            && !arrON
            && !buttonsInterface.arrOFFButton
            && !this.profilesService.hasMspOrXspLevelOne()
            && (check.isTrial
                || this.isUpgradeableBIS(bundle)
                || (check.isRenewableDpy && check.lastMonth)
                || (canHaveRenewLink && check.lastMonth && !check.isZuora
                    && (check.isLicense || check.isGooglePlay || check.isApple)))) {
            const source = this.buyLinksService.computeRenewSource(bundle);
            const campaign = this.buyLinksService.computeRenewCampaign(bundle);
            buttonsInterface.buyOrRenew.link = this.buyLinksService.buyAllBitdefenderLink;
            const properties = this.computeBuyOrRenewProperties(bundle);
            buttonsInterface.buyOrRenew.cta = properties.cta;
            buttonsInterface.buyOrRenew.description = properties.description;
        }

        if (isEligibleForSharingSub
            && !check.hasNoInvites
            && !check.isExpired
            && check.isPayer) {
            buttonsInterface.manageButtons.push(this.subscriptionsValuesService.manageButtons.editMembers);
        }

        return buttonsInterface;
    }

    isUpgradeableBIS(bundle) {
        return bundle.bundle_id === this.valuesService.bundleBIS
                && bundle.status === this.subscriptionsValuesService.status.active
                && !this.subscriptionsService.hasBIP();
    }

    computeBuyOrRenewProperties(bundle) {
        let cta = '';
        let description = '';

        if (bundle.type === this.subscriptionsValuesService.type.trial) {
            cta = 'subscriptions.buy.button';
            description = 'subscriptions.service.getyourcode';
        } else if (this.isUpgradeableBIS(bundle)) {
            cta = 'subscriptions.buy.button';
            description = 'subscriptions.service.upgrade.protection';
        } else {
            cta = 'subscriptions.service.renewNOW';
            description = 'subscriptions.service.renew';
        }
        return {description, cta};
    }

    createApplicationsAllocationInterface(bundle) {
        const applications = bundle.applications.filter(item => !this.valuesService.appsNotShownInManageDeviceAllocation.has(item.app_id));
        // alphabetically:
        applications.sort((app1, app2) => {
            const app1Name = app1.interface.appFriendlyName.toUpperCase();
            const app2Name = app2.interface.appFriendlyName.toUpperCase();

            if ((app1Name < app2Name)) {
                return -1;
            } else if (app1Name > app2Name) {
                return 1;
            } else {
                return 0;
            }
        });

        // by usage:
        applications.sort((app1, app2) => {
            const app1Usage = app1.interface.devices.length;
            const app2Usage = app2.interface.devices.length;

            if (app1Usage > app2Usage) {
                return -1;
            } else if (app1Usage < app2Usage) {
                return 1;
            } else {
                return 0;
            }
        });
        return applications;
    }

    createDevicesInfoAllocationInterface(bundle) {
        if (!bundle?.accounts) {
            return;
        }

        const appsForEveryDevice = {};
        for (const deviceId of bundle.interface.devices) {
            const appIdsForCurrentBundleAndDevice = [];
            const compatibleBundlesForEveryDevice = [];
            for (const account of bundle.accounts) {
                if (!account?.usage_summary || this.profilesService.getOwnerEmail() !== account?.email) {
                    continue;
                }

                for (const usage of account.usage_summary) {
                    if (usage.slot_id === deviceId) {
                        appIdsForCurrentBundleAndDevice.push(usage.app_id);
                    }
                }
            }

            for (const otherBundle of this.subscriptionsService.getAllServices()) {
                const otherAppIds = [];
                if (otherBundle.slots - otherBundle.interface.devicesNoBox >= 1
                    && !otherBundle.interface.expired
                    && otherBundle.service_id !== bundle.service_id) {
                        for (const application of otherBundle.applications) {
                            otherAppIds.push(application.app_id);
                        }

                        // testam egalitatea array-urilor de app ids
                        const equalitySet = new Set(Array.from(appIdsForCurrentBundleAndDevice).concat(Array.from(otherAppIds)));
                        if (equalitySet.size >= appIdsForCurrentBundleAndDevice.length && equalitySet.size === otherAppIds.length) {
                            compatibleBundlesForEveryDevice.push(otherBundle);
                        }
                }
            }
            appsForEveryDevice[deviceId] = {
                appIds: Array.from(appIdsForCurrentBundleAndDevice),
                bundles: compatibleBundlesForEveryDevice
            };
        }
        return appsForEveryDevice;
    }

    createDeviceAllocationInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        const allocation = {
            applications: this.createApplicationsAllocationInterface(bundle),
            devices: this.createDevicesInfoAllocationInterface(bundle)
        };

        bundle.interface.allocation = allocation;
    }

    createBadgeInterface(bundle) {
        // conteaza ordinea !!
        const badgeChecks = {
            [this.subscriptionsValuesService.badgesTypes.expiringToday]:  this.subscriptionsHelperService.expiringToday(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredToday]:   this.subscriptionsHelperService.expiredToday(bundle),
            [this.subscriptionsValuesService.badgesTypes.trial]:          this.subscriptionsHelperService.isTrial(bundle),
            [this.subscriptionsValuesService.badgesTypes.new]:            this.subscriptionsHelperService.isNew(bundle),
            [this.subscriptionsValuesService.badgesTypes.endingInDay]:    this.subscriptionsHelperService.isEndingInDay(bundle),
            [this.subscriptionsValuesService.badgesTypes.endingInDays]:   this.subscriptionsHelperService.isEndingInDays(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredDayAgo]:  this.subscriptionsHelperService.expiredDayAgo(bundle),
            [this.subscriptionsValuesService.badgesTypes.expiredDaysAgo]: this.subscriptionsHelperService.expiredDaysAgo(bundle)
        };

        for (const badgeScenario in badgeChecks) {
            if (badgeChecks[badgeScenario] && this.subscriptionsValuesService.badges[badgeScenario]) {
                return this.subscriptionsValuesService.badges[badgeScenario];
            }
        }
        return {};
    }

    createApplicationsInterface(bundle, installModalAppsInterface: InstallModalAppsInterface) {
        const protectionAppsForTotalSecurityInterface: InstallModalAppsInterface = {};
        for (const application of bundle.applications) {
            const devices = this.getAppDevices(application, bundle);
            const appInterface = {
                image:            this.productsConfigService.getAppIcon(application),
                installIncentive: this.getAppInstallIncentive(application),
                appIdLevel:       this.getAppIdLevel(application),
                appFriendlyName:  this.productsConfigService.getAppName(application),
                devices:          devices,
                usage:            this.computeAppsDeviceAllocationUsageInterface(devices.length),
                deviceName:       '',
            };
            application.interface = appInterface;

            this.addProtectionAppsInfoAndOtherAppsInfoToSeparateObjects(protectionAppsForTotalSecurityInterface, installModalAppsInterface, application, bundle, devices);

            if (bundle.life_cycle === this.subscriptionsValuesService.lifeCycle.lifetime) {
                if (devices.length > 0) {
                    application.interface.deviceName = this.getDeviceNameFromDevices(application, bundle, devices);
                } else {
                    this.getDeviceNameFromService(application, bundle).then(resp => {
                        application.interface.deviceName = resp;
                    });
                }
            }
        }

        this.addProtectionAppsInfoToInstallModalInterface(protectionAppsForTotalSecurityInterface, installModalAppsInterface, bundle);
    }

    /**
     * Adds the interface info of the given app to the protection apps included in total security interface info or to the interface info of the other apps
     * @param protectionAppsForTotalSecurityInterface The protection apps included in total security interface info
     * @param installModalAppsInterface The interface info for all the other apps that are not protection apps included in total security
     * @param app The given app
     * @param bundle The bundle that contains the given app
     * @param devices The devices on which the given app is installed
     */
    private addProtectionAppsInfoAndOtherAppsInfoToSeparateObjects(protectionAppsForTotalSecurityInterface: InstallModalAppsInterface,
                                                                    installModalAppsInterface: InstallModalAppsInterface,
                                                                    app, bundle, devices): void {
        let destinationRefference;
        const level = app?.app_params?.level ?? NO_LEVEL;
        const finalKey = this.concatAppIdAndLevel(app.app_id, level);
        if (this.appsConfigService.isContainedInInstallModalTotalSecurityApp(app)) {
            destinationRefference = protectionAppsForTotalSecurityInterface;
        } else {
            destinationRefference = installModalAppsInterface;
        }

        if (!destinationRefference[finalKey]) {
            destinationRefference[finalKey] = {
                occupiedSlots: 0,
                totalSlots: 0
            };
        }

        destinationRefference[finalKey].occupiedSlots += devices.length;
        destinationRefference[finalKey].totalSlots += bundle.slots;
    }

    /**
     * Adds the interface info of the protection apps included in total security to the interface info of the other apps.
     * If the interface info of the protection apps included in total security is complete, adds another total securoty app to the interface,
     * otherwise every protection app is added separetely to the interface
     * @param protectionAppsForTotalSecurityInterface The protection apps included in total security interface info
     * @param installModalAppsInterface The interface info for all the other apps that are not protection apps included in total security
     * @param bundle The bundle that contains the given app
     */
    private addProtectionAppsInfoToInstallModalInterface(protectionAppsForTotalSecurityInterface: InstallModalAppsInterface,
                                                        installModalAppsInterface: InstallModalAppsInterface, bundle): void {
        if (Object.keys(protectionAppsForTotalSecurityInterface).length === this.appsConfigService.getNumberOfApsForTotalSecurity()) {
            let usedDevices = 0;
            for (const finalKey in protectionAppsForTotalSecurityInterface) {
                usedDevices += protectionAppsForTotalSecurityInterface[finalKey].occupiedSlots;
            }

            const allPlatformAndLevel = this.concatAppIdAndLevel(ALL_PLATFORMS, NO_LEVEL);
            if (!installModalAppsInterface[allPlatformAndLevel]) {
                installModalAppsInterface[allPlatformAndLevel] = {
                    occupiedSlots: 0,
                    totalSlots: 0
                };
            }
            installModalAppsInterface[allPlatformAndLevel].occupiedSlots += usedDevices;
            installModalAppsInterface[allPlatformAndLevel].totalSlots += bundle.slots;
        } else {
            for (const finalKey in protectionAppsForTotalSecurityInterface) {
                if (!installModalAppsInterface[finalKey]) {
                    installModalAppsInterface[finalKey] = {
                        occupiedSlots: 0,
                        totalSlots: 0
                    };
                }
                installModalAppsInterface[finalKey].occupiedSlots += protectionAppsForTotalSecurityInterface[finalKey].occupiedSlots;
                installModalAppsInterface[finalKey].totalSlots += bundle.slots;
            }
        }
    }

    /**
     * Concats the app id and the level of the app
     * @param appId The app id
     * @param level The level of the app
     * @returns {string} The app id and the level of the app concatenated
     */
    public concatAppIdAndLevel(appId: string, level: string): string {
        return appId.concat(level);
    }

    /**
     * Get install interface info for a given app
     * @param appAndLevel The app id and level concatenated
     * @returns {InstallModalAppInterface} The install interface info for the given app
     */
    public getInstallableAppInfo(appAndLevel: string): InstallModalAppInterface {
        return this.installModalAppsInterface?.[appAndLevel] ?? null;
    }

    /**
     * Get the install interface total slots a given app can occupy
     * @param appAndLevel The app id and level concatenated
     * @returns {number} The install interface total slots a given app can occupy
     */
    public getInstallableAppTotalSlots(appAndLevel: string): number {
        return this.installModalAppsInterface?.[appAndLevel]?.totalSlots ?? 0;
    }

    /**
     * Get the install interface total slots a given app already occupies
     * @param appAndLevel The app id and level concatenated
     * @returns {number} The install interface total slots a given app already occupies
     */
    public getInstallableAppOccupiedSLots(appAndLevel: string): number {
        return this.installModalAppsInterface?.[appAndLevel]?.occupiedSlots ?? 0;
    }

    /**
     * Create shared invites interface for given bundle if it is eligible to share the subscription
     *
     * @private
     * @memberof ISubscriptionsService
     * @param {Object} bundle
     * @returns {Object}
     */
    private createInvitesInterface(bundle: BundleModel): object {
        const isEligibleForSharingSub = this.subscriptionsService.isEligibleForSharingSubscription(bundle);
        const isPayer = this.subscriptionsService.isSubscriptionPayer(bundle);
        const pendingInvites = this.invitesService.getInvitesByServiceId(bundle.service_id);
        const maxShares = this.subscriptionsService.getBundleMaxShares(bundle);
        const gainersArr = bundle?.processed?.sharedInfo?.gainers ?? [];

        const invitesInfo = {
            manageInvites: [],
            availableInvites: 0
        };
        const inviteTemplate = {
            email: '',
            inviteId: '',
            inviteLink: '',
            expireAt: '',
            status: ''
        };

        if (!isEligibleForSharingSub || !isPayer) {
            return invitesInfo;
        }

        // First of all, push all active invites
        for (const gainer of gainersArr) {
            gainer.status = SubscriptionInviteStatus.ACTIVE;
            invitesInfo.manageInvites.push(gainer);
        }

        // Secondly, push pending invites
        for (const invite of pendingInvites) {
            const tempInvite = {
                email: invite.invited_email,
                inviteId: invite.invite_id,
                inviteLink: invite.invite_link,
                expireAt: invite.expire_at,
                status: SubscriptionInviteStatus.INVITED
            };
            invitesInfo.manageInvites.push(tempInvite);
        }

        // Thirdly, the number of remaining invites is calculated
        invitesInfo.availableInvites = maxShares - invitesInfo.manageInvites.length;
        for (let i = 0; i < invitesInfo.availableInvites; i++) {
            invitesInfo.manageInvites.push(inviteTemplate);
        }

        return invitesInfo;
    }

    /**
     * Function that computes the usage and expiry for bundle and bundle apps for general display
     * (in /subscription/service) and device allocation (in device allocation modal for bundle and app)
     * We need to gather all logic inside one function in order to better control how we show/hide usage and expiry across subscription module
     * @param bundle Bundle object
     */
    computeExpiryAndUsageInterface(bundle) {
        let expiryAndUsageInterface = {
            devicesUsage: '',
            expiryInfo: '',
            step2Usage: '',
            step2WillHave: '',
            serviceWillEnd: '',
            step1Header: '',
            step2Remove: ''
        };

        expiryAndUsageInterface = this.computeGeneralExpiryAndUsageInterface(bundle, expiryAndUsageInterface);
        expiryAndUsageInterface = this.computeDeviceAllocationExpiryAndUsageInterface(bundle, expiryAndUsageInterface);
        bundle.interface.expiryAndUsage = expiryAndUsageInterface;
        return expiryAndUsageInterface;
    }

    computeGeneralExpiryAndUsageInterface(bundle, expiryAndUsageInterface) {
        const strings                   = this.subscriptionsValuesService.strings;
        const slotsAllocationCases      = this.subscriptionsHelperService.computeSlotsAllocationCases(bundle);
        const devicesAllocationCases    = this.subscriptionsHelperService.computeDevicesAllocationCases(bundle);
        const isDpy                     = this.subscriptionsService.isDpy(bundle);
        const isIdTP                    = this.subscriptionsService.isIdTheftProtection(bundle);
        const isPM                      = this.subscriptionsService.isPasswordManager(bundle);
        const isSI                      = this.subscriptionsService.isSecureIdentity(bundle);
        const isActive                  = bundle.status === this.subscriptionsValuesService.status.active;
        const isSubscription            = bundle.service_type === this.subscriptionsValuesService.serviceType.subscription;
        const isBox                     = this.subscriptionsService.isBox(bundle);
        const isEligibleForSharingSub   = this.subscriptionsService.isEligibleForSharingSubscription(bundle);
        const isGainer                  = this.subscriptionsService.isSubscriptionGainer(bundle);

        // usage and expiry in subscriptions-service component
        if (isActive) {
            expiryAndUsageInterface.devicesUsage = this.subscriptionsValuesService.generalUsageCases?.[slotsAllocationCases]?.[devicesAllocationCases]
                                                    ?? this.subscriptionsValuesService.generalUsageCases?.[slotsAllocationCases];

            if (isSubscription) {
                if (bundle?.processed?.arrON) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.servicedetails.nextbilling.subscription';
                    expiryAndUsageInterface.expiryDate = bundle.interface.renewalDateLocale;
                } else if (bundle?.processed?.arrOFF || bundle?.end_date) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.servicedetails.willendon.subscription';
                    expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
                }
            } else if (bundle.type !== this.subscriptionsValuesService.type.free && !bundle.interface.hideEndDate) {
                if (bundle?.processed?.arrON) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.services.licenserenew';
                    // pt licentele de avangate ce au ar pe on si au end date, afisam end date in loc de billing date
                    if ((this.subscriptionsService.isAvangate(bundle) || this.subscriptionsService.isDigitalRiver(bundle))
                        && bundle?.interface?.endDateLocale) {
                        expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
                    } else {
                        expiryAndUsageInterface.expiryDate = bundle.interface.renewalDateLocale;
                    }
                } else if (bundle?.processed?.arrOFF || bundle?.end_date) {
                    expiryAndUsageInterface.expiryInfo = 'subscriptions.services.licenseend';
                    expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
                }
            }

        } else {
            expiryAndUsageInterface.devicesUsage = bundle.interface.devicesNoBox === 1 ? strings.wasActiveOnDevice : strings.wasActiveOnDevices;
            expiryAndUsageInterface.expiryInfo = (isSubscription && bundle?.end_date)
                                                ? 'subscriptions.services.subscriptionexpired'
                                                : 'subscriptions.services.licenseexpired';
            expiryAndUsageInterface.expiryDate = bundle.interface.endDateLocale;
        }

        // hide usage in subscriptions-service
        if ((isBox && bundle.interface.devicesNoBox >= this.subscriptionsValuesService.slotsNo.limit)
            || bundle.slots <= 0
            || isEligibleForSharingSub
            || isDpy
            || isIdTP
            || isPM
            || isSI) {
            expiryAndUsageInterface.devicesUsage = '';
        }

        // hide expiry in subscriptions-service
        if (bundle?.interface?.owner || (isGainer && !isEligibleForSharingSub)) {
            expiryAndUsageInterface.expiryInfo = '';
        }

        return expiryAndUsageInterface;
    }

    computeDeviceAllocationExpiryAndUsageInterface(bundle, expiryAndUsageInterface) {
        const slotsAllocationCases      = this.subscriptionsHelperService.computeSlotsAllocationCases(bundle);
        const devicesAllocationCases    = this.subscriptionsHelperService.computeDevicesAllocationCases(bundle);
        const showUsedBy                = !this.valuesService.boxBundles.has(bundle.bundle_id)
                                            || (this.valuesService.boxBundles.has(bundle.bundle_id) && bundle.interface.devicesNoBox === 0);
        const usageCases = this.subscriptionsValuesService.serviceSlotsAllocationCases?.[slotsAllocationCases]?.[devicesAllocationCases]
                            ?? this.subscriptionsValuesService.serviceSlotsAllocationCases?.[slotsAllocationCases];

        // usage and expiry in device allocation modal
        if (showUsedBy) {
            expiryAndUsageInterface.step1Header = usageCases?.[DeviceAllocationDisplayComponents.STEP1_HEADER]?.[bundle.service_type];
            expiryAndUsageInterface.step2WillHave = usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]?.[bundle.service_type]
                                                    ?? usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_SINGLE]?.[bundle.service_type];
        } else {
            expiryAndUsageInterface.step1Header = usageCases?.[DeviceAllocationDisplayComponents.USED_BY_BOX]?.[bundle.service_type];
            expiryAndUsageInterface.step2WillHave = usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE]?.[bundle.service_type]
                                                    ?? usageCases?.[DeviceAllocationDisplayComponents.STEP2_WILL_HAVE_MULTI]?.[bundle.service_type];
        }

        if (!bundle.interface.hideEndDate) {
            expiryAndUsageInterface.serviceWillEnd = usageCases?.[DeviceAllocationDisplayComponents.SERVICE_WILL_END]?.[bundle.service_type];
        }
        expiryAndUsageInterface.step2Usage = usageCases?.[DeviceAllocationDisplayComponents.STEP2_USAGE]?.[bundle.service_type];
        expiryAndUsageInterface.step2Remove = usageCases?.[DeviceAllocationDisplayComponents.STEP2_REMOVE]?.[bundle.service_type];

        return expiryAndUsageInterface;
    }

    computeAppsDeviceAllocationUsageInterface(devicesLength) {
        const allocationCase = this.subscriptionsHelperService.computeAllocationCaseForApplication(devicesLength);
        return this.subscriptionsValuesService.serviceApplicationsDevicesAllocationCases[allocationCase] ?? null;
    }

    computeConvertToSubscriptionInterface(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        let features = [];
        const protectionFeature1 = bundle.slots === 1
                                ? 'subscriptions.convertservice.included.protection.device.included'
                                : 'subscriptions.convertservice.included.protection.devices.included';
        const protectionFeature2 = bundle.slots === 1
                                ? 'subscriptions.convertservice.included.protection.single'
                                : 'subscriptions.convertservice.included.protection.multiple';
        if (bundle.bundle_id === this.valuesService.bundleIS) {
            features = [
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                'subscriptions.convertservice.included.description.all.parental',
                'subscriptions.convertservice.included.description.all.extensive',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else if (bundle.bundle_id === this.valuesService.bundleCLTSMD || bundle.bundle_id === this.valuesService.bundleTS) {
            features = [
                'subscriptions.convertservice.included.description.ts.complete',
                'subscriptions.convertservice.included.description.ts.protection',
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                'subscriptions.convertservice.included.description.all.parental',
                'subscriptions.convertservice.included.description.all.extensive',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else if (bundle.bundle_id === this.valuesService.bundleAV) {
            features = [
                'subscriptions.convertservice.included.description.all.1',
                'subscriptions.convertservice.included.description.all.2',
                protectionFeature1,
                'subscriptions.convertservice.included.price.year'
            ];
        } else {
            features = [
                protectionFeature2,
                'subscriptions.convertservice.included.yearly.renewal',
                'subscriptions.convertservice.included.price.year'
            ];
        }

        if (!bundle.interface?.convert) {
            bundle.interface.convert = {};
        }

        const newBillingDate = this.computeOverOneYearBillingDate(serviceId);
        if (features.length >= 3 && features[2] === 'subscriptions.convertservice.included.yearly.renewal' && !newBillingDate) {
            features.splice(2, 1);
        }
        bundle.interface.convert.features = features;
        bundle.interface.convert.newBillingDate = newBillingDate;
    }

    computeOverOneYearBillingDate(serviceId) {
        const bundle = this.subscriptionsService.retrieveServiceByServiceId(serviceId);
        if (bundle.status === this.subscriptionsValuesService.status.expired) {
            return moment().add(1, 'year').format('MM/DD/YYYY');
        } else if (bundle?.end_date) {
            return moment.unix(bundle.end_date).add(1, 'year').format('MM/DD/YYYY');
        }
        return '';
    }

    /**
     * This functions computes the flags needed to show/hide install text for a bundle across subscriptions module
     * For password manager is a special case, where install text includes product name
     * @param bundle Bundle object
     * @returns
     */
    computeInstallInterface(bundle) {
        const cantHaveInstallProtection = this.valuesService.bundlesWithoutDeployFlow.has(bundle.bundle_id);
        const bundleHasFreeSlots  = bundle.interface.devicesNoBox < bundle.slots || bundle.bundle_id === this.valuesService.bundlePM;
        const isActive = bundle.status === this.subscriptionsValuesService.status.active;
        const lessThanAWeekValability = bundle.interface.daysRemaining <= this.subscriptionsValuesService.weekDays;

        // aceste flaguri fac sa apara flowul de deploy pentru un serviciu
        // daca install protection e true, apare textul "Install protection on a device" pentru un bundle care deschide modala de install
        // daca installProduct e true, apare textul "Install <productName> on a device" care deschide modala de install
        // daca installProduct e pus pe true, trebuie dat si o valoare lui productName
        // daca showBuyOrRenew e true, nu avem flow de deploy in modala de install pt unul din app-urile idn bundle,
        // dar afisam linkuri de buy/renew/ etc pentru acel bundle
        const installInterface = {
            installProtection: false,
            installProduct: false,
            showBuyOrRenew: false,
            productName: ''
        };

        // conditii pentru care nu avem flow de deploy pentru bundle
        if (cantHaveInstallProtection
            || !this.appsConfigService.showDployFlowForBundle(bundle)) {
            return installInterface;
        }

        // daca a expirat, poate avea link de buy/renew
        if (!isActive) {
            installInterface.showBuyOrRenew = true;
            return installInterface;
        }

        if (this.subscriptionsService.isPasswordManager(bundle) || this.subscriptionsService.isSecureIdentity(bundle)) {
            installInterface.installProduct = true;
            installInterface.productName = this.productsConfigService.getProductName(this.valuesService.productNamePasswordManager);
        } else if (this.subscriptionsService.isIdTheftProtection(bundle)) {
            if (lessThanAWeekValability) {
                installInterface.showBuyOrRenew = true;
            }
        } else if (this.subscriptionsService.isUltimateSecurityProtection(bundle)) {
            if (bundleHasFreeSlots) {
                installInterface.installProtection = true;
            }

            if (lessThanAWeekValability) {
                installInterface.showBuyOrRenew = true;
            }
        } else if (bundleHasFreeSlots) {
            installInterface.installProtection = true;
        }
        return installInterface;
    }

    bundleHasInstallButton(bundle) {
        return bundle?.interface?.install?.installProtection || bundle?.interface?.install?.installProduct;
    }

    getAppInstallIncentive(app) {
        let installIncentive;
        if (this.subscriptionsValuesService.installIncentives[app.app_id]) {
            if (app.app_params && app.app_params.level && this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level]) {
                installIncentive = this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level];
            } else {
                installIncentive = this.subscriptionsValuesService.installIncentives[app.app_id];
            }
        }
        return installIncentive;
    }

    // returns app_id.level (ex: com.bitdefender.vpn.premium) or just app_id
    getAppIdLevel(app) {
        let appIdLevel;
        if (this.subscriptionsValuesService.installIncentives[app.app_id]) {
            if (app.app_params && app.app_params.level && this.subscriptionsValuesService.installIncentives[app.app_id][app.app_params.level]) {
                appIdLevel = `${app.app_id}.${app.app_params.level}`;
            } else {
                appIdLevel = app.app_id;
            }
        }
        return appIdLevel;
    }

    getAppDevices(app, bundle) {
        const knownDevices = [];
        const unknownDevices = [];
        const accounts = bundle?.accounts;

        if (!accounts) {
            return [];
        }
        for (const account of accounts) {
            const usages = account?.usage_summary;
            if (!usages || this.profilesService.getOwnerEmail() !== account.email) {
                continue;
            }
            for (const usage of usages) {
                if (!this.valuesService.appsWithoutActiveDevices.has(app.app_id) && app.app_id === usage.app_id) {
                    const device = this.devicesService.retrieveDeviceById(usage.slot_id);
                    let deviceWithApp = {};
                    let options = {};
                    if (device) {
                        deviceWithApp = device;
                        options = {
                            change: this.subscriptionsValuesService.deviceAllocationOptions.change,
                            remove: this.subscriptionsValuesService.deviceAllocationOptions.remove
                        };
                        this.devicesService.processingMethods.addSubscriptionInfo(deviceWithApp, bundle, options);
                        knownDevices.push(device);
                    } else {
                        deviceWithApp = {
                            device_os: 'unknown',
                            device_icon: 'other',
                            display_name: this.translateService.instant('subscriptions.deviceallocation.removeddevice'),
                            removed: true,
                            device_id: usage.slot_id
                        };
                        options = {
                            remove: this.subscriptionsValuesService.deviceAllocationOptions.remove
                        };
                        this.devicesService.processingMethods.addSubscriptionInfo(deviceWithApp, bundle, options);
                        unknownDevices.push(deviceWithApp);
                    }
                }
            }
        }
        return knownDevices.concat(unknownDevices);
    }

    getDeviceNameFromService(app, bundle): Promise<any> {
        return new Promise((resolve, reject) => {
            bundle.accounts.forEach(account => {
                account.usage_summary.forEach(usage => {
                    if (usage.app_id === app.app_id && usage.slot_id) {
                        this.connectMgmtService.getDeviceInfo(usage.slot_id, 1).subscribe(resp => {
                            resolve(resp['display_name']);
                        });
                    }
                });
            });
        });
    }

    /**
     *
     * Get device name for slot_id from devices
     * @private
     * @param {*} application
     * @param {*} bundle
     * @param {*} devices
     * @return {*}  {string}
     * @memberof ISubscriptionsService
     */
    private getDeviceNameFromDevices(application, bundle, devices): string {
        for (const account of bundle.accounts) {
            for (const usage of account.usage_summary) {
                if (usage.app_id === application.app_id) {
                    for (const device of devices) {
                        if (device.device_id === usage.slot_id) {
                            return device.display_name;
                        }
                    }
                }
            }
        }
        return '';
    }

}
