// External
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, concatMap, defer, forkJoin, map, of, skipWhile, throwError } from 'rxjs';
//Internal
import { ConnectWebmailProtectionService } from '../../requests/connect-webmailprotection-service/connect-webmailprotection.service';
import { V1GmailTokensService } from '../../requests/v1-gmail-tokens/v1-gmail-tokens.service';
import { V1OutlookTokensService } from '../../requests/v1-outlook-tokens/v1-outlook-tokens.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LinksService } from '../../../../common/links/links.service';
import { ONE_DAY_IN_MILISECONDS, ValuesService } from '../../../../common/values/values.service';
import { Errors } from '../../global/request-service/requests.service';
import { ThreatsCounter, Threat, ThreatsGetDetailsRequestParameters } from '../../../../common/models/Threats.model';
import { Security } from '../../../../common/models/security/Security.model';
import { WebmailProtectionValuesService } from '../../../../common/values/webmailprotection.values.service';
import { SeccenterService } from '../../requests/connect-seccenter-service/connect-seccenter.service';
import { ConnectWebmailRegistrationService, ConnectWebmailRegistrationServiceErrorSubCodes } from '../../requests/connect-webmailregistration/connect-webmailregistration.service';
import {
    WebmailProtectionInbox,
    WebmailProtectionInboxSummary,
    WebmailProtectionSummary,
    WebmailProtectionInboxActivity,
    WebmailProtectionKeySpecificTypes,
    WebmailProtectionKey,
    WebmailProtectionOauth2Tokens,
    WebmailProtectionInboxProviders,
    WebmailProtectionOauth2GmailTokenInfoSuccessResponse,
    WebmailProtectionOauth2GmailTokenInfoErrorResponse,
    WebmailProtectionOauth2Errors,
    WebmailProtectionOauth2OutlookTokenInfoErrorResponse,
    WebmailProtectionOauth2OutlookTokenInfoSuccessResponse,
    WebmailProtectionKeyAlgorithm,
    WebmailProtectionKeyHash,
    WebmailProtectionKeyUsage,
    WebmailProtectionKeyFormat,
    WebmailProtectionOauth2EncryptedTokens,
    WebmailProtectionOauth2TokenInfoErrorFalgs
} from '../../../models/security/WebmailProtection.model';

@Injectable({
    providedIn: 'root'
})

export class WebmailProtectionService {

    private readonly inboxes: Security.EmailProtection.InboxesProcessLayer = {
        markToUpdate: true,
        onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
        data: [],
        error: false
    };

    private readonly inboxesActivity: Security.EmailProtection.InboxesActivityProcessLayer = {
        markToUpdate: true,
        onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
        data: [],
        error: false
    };

    private readonly summary: Security.EmailProtection.SummayProcessLayer = {
        onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
        byDay: [],
        byInbox: [],
        error: false
    };

    private keys: WebmailProtectionKey[];
    private tokens: WebmailProtectionOauth2Tokens;
    private readonly encryptedTokens: WebmailProtectionOauth2EncryptedTokens = {
        access_token: [],
        refresh_token: []
    };
    private email: string;

    private readonly oAuthErrors: WebmailProtectionOauth2TokenInfoErrorFalgs = {
        notEnoughPermissions: false,
        defaultError: false,
        emailAlreadyRegistered: false,
        maximumNumberOfEmailsReached: false,
        wrongCredentials: false
    };

    private readonly threatsGroupedByTime: Security.Overview.ThreatsProcessLayer = {
        [Security.Overview.Filter.LAST_7D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        },
        [Security.Overview.Filter.LAST_30D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        }
    };

    private readonly threats: Security.Overview.ThreatsProcessLayer = {
        [Security.Overview.Filter.LAST_7D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        },
        [Security.Overview.Filter.LAST_30D]: {
            markToUpdate: true,
            onList: new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING),
            data: [],
            error: false
        }
    };

    constructor(
        private readonly connectWebmailProtectionService: ConnectWebmailProtectionService,
        private readonly connectWebmailRegistrationService: ConnectWebmailRegistrationService,
        private readonly v1GmailTokensService: V1GmailTokensService,
        private readonly v1OutlookTokensService: V1OutlookTokensService,
        private readonly linksService: LinksService,
        private readonly valuesService: ValuesService,
        private readonly http: HttpClient,
        private readonly seccenterService: SeccenterService,
        private readonly webmailProtectionValuesService: WebmailProtectionValuesService
    ) {}

    /**
     * Gets and saves the inboxes.
     * @public
     * @memberof WebmailProtectionService
     * @returns {Observable} Response from server
     */
    public listInboxes(): Observable<any> {
        if (!this.inboxes.markToUpdate) {
            return of(this.inboxes.markToUpdate);
        }

        if (this.inboxes.onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.inboxes.onList.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.inboxes.onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectWebmailProtectionService.listInboxes()
            .pipe(
                map(resp => {
                    if (!resp?.inboxes) {
                        throw resp;
                    }

                    this.inboxes.data = resp.inboxes;
                    this.inboxes.markToUpdate = false;
                    this.inboxes.error = false;
                    this.inboxes.onList.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(err => {
                    this.inboxes.data = [];
                    this.inboxes.error = true;
                    this.inboxes.markToUpdate = true;
                    this.inboxes.onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Registers the inbox to the server.
     * @param {string} userToken User token from the endpoint.
     * @param {WebmailProtectionInboxProviders} inboxProvider Inbox provider from the endpoint.
     * @returns {Observable<any>} The response from the server.
     */
    public registerInbox(userToken: string, inboxProvider: WebmailProtectionInboxProviders): Observable<any> {
        const registerInboxParameters = {
            inbox_provider: inboxProvider,
            email: this.email,
            access_token: this.encryptedTokens.access_token,
            refresh_token: this.encryptedTokens.refresh_token,
            user_token: userToken,
            kid: this.getCurrentlyUsedKey().kid
        };
        return this.connectWebmailRegistrationService.registerInbox(registerInboxParameters)
        .pipe(
            map(resp => resp),
            catchError(err => {
                if (err?.error?.data?.code === ConnectWebmailRegistrationServiceErrorSubCodes.INBOX_ALREADY_REGISTERED) {
                    this.oAuthErrors.emailAlreadyRegistered = true;
                } else if (err?.error?.data?.code === ConnectWebmailRegistrationServiceErrorSubCodes.NO_FREE_SLOTS) {
                    this.oAuthErrors.maximumNumberOfEmailsReached = true;
                } else if (err?.error?.data?.code === ConnectWebmailRegistrationServiceErrorSubCodes.WRONG_CREDENTIALS) {
                    this.oAuthErrors.wrongCredentials = true;
                } else {
                    this.oAuthErrors.defaultError = true;
                }
                throw err;
            })
        );
    }

    /**
     * Gets the email of the authenticated account.
     * @param {WebmailProtectionInboxProviders} inboxProvider The inbox provider in order to decide the used api.
     * @returns {Observable<any>} The response from the api.
     */
    public listEmail(inboxProvider: WebmailProtectionInboxProviders): Observable<any> {
        switch (inboxProvider) {
            case WebmailProtectionInboxProviders.GMAIL:
                return this.listGmailEmail();
            case WebmailProtectionInboxProviders.OUTLOOK:
                return this.listOutlookEmail();
            default:
                this.oAuthErrors.defaultError = true;
                return throwError(() => new Error(Errors.ERROR));
        }
    }

    /**
     * Returns if not enough permissions error occured.
     * @returns {boolean} True if not enough permissions error occured.
     */
    public getOAuthErrors(): WebmailProtectionOauth2TokenInfoErrorFalgs {
        return this.oAuthErrors;
    }

    /**
     * Gets the email of the authenticated gmail account.
     * @returns {Observable<any>} The response from the api.
     */
    private listGmailEmail(): Observable<any>  {
        return this.http.get(`${this.linksService.getGmailTokenInfoUrl()}?${this.valuesService.queryParams.accessToken}=${this.tokens.access_token}`)
        .pipe(
            map((resp: WebmailProtectionOauth2GmailTokenInfoSuccessResponse) => {
                if (this.checkIfNotEnoughPermissionsAreGivenForGmail(resp?.scope ?? '')) {
                    this.oAuthErrors.notEnoughPermissions = true;
                    throw resp;
                }
                this.email = resp.email;
                return true;
            }),
            catchError((error: WebmailProtectionOauth2GmailTokenInfoErrorResponse) => {
                if (error.error === WebmailProtectionOauth2Errors.ACCESS_DENIED) {
                    this.oAuthErrors.notEnoughPermissions = true;
                } else {
                    this.oAuthErrors.defaultError = true;
                }
                throw error;
            })
        );
    }

    /**
     * Checks if not enough permissions error occured for gmail.
     * @param {string} scopes The scopes returned by the google oauth2 callback. It is a concatenated string of scopes,
     * separated by spaces.
     * @returns {boolean} True if not enough permissions error occured.
     */
    private checkIfNotEnoughPermissionsAreGivenForGmail(scopes: string): boolean {
        const gmailScopes = this.linksService.getWebmailProtectionGmailOauth2Scopes();
        for (const scope of gmailScopes) {
            if (!scopes.includes(scope)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Gets the email of the authenticated outlook account.
     * @returns {Observable<any>} The response from the api.
     */
    private listOutlookEmail(): Observable<any>  {
        const httpOptions = {
            headers: new HttpHeaders({
                'Host': 'graph.microsoft.com',
                'Authorization': `Bearer ${this.tokens.access_token}`
            })
        };

        return this.http.get(this.linksService.getOutlookTokenInfoUrl(), httpOptions)
        .pipe(
            map((resp: WebmailProtectionOauth2OutlookTokenInfoSuccessResponse) => {
                this.email = resp.mail;
                return true;
            }),
            catchError((error: WebmailProtectionOauth2OutlookTokenInfoErrorResponse) => {
                if (error.code === WebmailProtectionOauth2Errors.ACCESS_DENIED) {
                    this.oAuthErrors.notEnoughPermissions = true;
                } else {
                    this.oAuthErrors.defaultError = true;
                }
                throw error;
            })
        );
    }

    /**
     * Requests the tokens for register and saves them locally.
     * @param {string} code The code returned by oauth2 callback
     * @param {WebmailProtectionInboxProviders} inboxProvider The inbox provider in order to decide the used api
     * @returns {Observable<any>}
     */
    public listTokens(code: string, inboxProvider: WebmailProtectionInboxProviders): Observable<any> {
        switch (inboxProvider) {
            case WebmailProtectionInboxProviders.GMAIL:
                return this.listGmailTokens(code);
            case WebmailProtectionInboxProviders.OUTLOOK:
                return this.listOutlookTokens(code);
            default:
                this.oAuthErrors.defaultError = true;
                return throwError(() => new Error(Errors.ERROR));
        }
    }

    /**
     * Requests the tokens for gmail register and saves them locally.
     * @param {string} code The code returned by the google oauth2 callback
     * @returns {Observable<any>} The response from the server
     */
    private listGmailTokens(code: string): Observable<any> {
        return this.v1GmailTokensService.listTokens(code)
        .pipe(
            map(resp => {
                this.tokens = resp;
                return true;
            }),
            catchError(error => {
                this.oAuthErrors.defaultError = true;
                throw(error);
            })
        );
    }

    /**
     * Requests the tokens for outlook register and saves them locally.
     * @param {string} code The code returned by the microsoft oauth2 callback
     * @returns {Observable<any>} The response from the server
     */
    private listOutlookTokens(code: string): Observable<any> {
        return this.v1OutlookTokensService.listTokens(code)
        .pipe(
            map(resp => {
                this.tokens = resp;
                return true;
            }),
            catchError(error => {
                this.oAuthErrors.defaultError = true;
                throw(error);
            })
        );
    }

    /**
     * Gets the array of inboxes available for the user.
     * @public
     * @memberof WebmailProtectionService
     * @returns {WebmailProtectionInbox[]} The inboxes array.
     */
    public getInboxes(): WebmailProtectionInbox[] {
        return this.inboxes.data ?? [];
    }

    /**
     * Gets the inboxes error.
     * @returns {boolean} True if error occured.
     */
    public getInboxesError(): boolean {
        return this.inboxes.error;
    }

    /**
     * Gets the number of inboxes available for the user.
     * @public
     * @memberof WebmailProtectionService
     * @returns {number} The number of registered inboxes.
     */
    public getInboxesNumber(): number {
        return this.inboxes.data?.length ?? 0;
    }

    /**
     * Gets the inboxes activity and computes activity history per email.
     * @public
     * @memberof WebmailProtectionService
     * @returns {Observable} Response from server
     */
    public listInboxesActivity(): Observable<any> {
        if (!this.getInboxesNumber()) {
            this.inboxesActivity.data = [];
            return of(true);
        }

        if (!this.inboxesActivity.markToUpdate) {
            return of(this.inboxesActivity.markToUpdate);
        }

        if (this.inboxesActivity.onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.inboxesActivity.onList.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            return this.connectWebmailProtectionService.listInboxActivity()
            .pipe(
                map(resp => {
                    if (!resp?.length) {
                        throw resp;
                    }

                    this.inboxesActivity.data = resp;
                    this.inboxesActivity.error = false;
                    this.inboxesActivity.markToUpdate = false;
                    this.inboxesActivity.onList.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(err => {
                    this.inboxesActivity.data = [];
                    this.inboxesActivity.error = true;
                    this.inboxesActivity.markToUpdate = true;
                    this.inboxesActivity.onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Gets the array of activity summary for inboxes.
     * @public
     * @memberof WebmailProtectionService
     * @returns {WebmailProtectionInboxActivity[]} The array of days with activity summary.
     */
    public getInboxesActivity(): WebmailProtectionInboxActivity[] {
        return this.inboxesActivity.data ?? [];
    }

    /**
     * Gets the inboxes activity error.
     * @returns {boolean} True if error occured.
     */
    public getInboxesActivityError(): boolean {
        return this.inboxesActivity.error;
    }

    /**
     * Marks 'list_inboxes' request to be updated
     * @public
     * @memberof WebmailProtectionService
     */
    public updateWebmailProtectionListInboxes(): void {
        if (this.inboxes.onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.inboxes.markToUpdate = true;
        }
    }

    /**
     * Marks 'get_inbox_activity' request to be updated
     * @public
     * @memberof WebmailProtectionService
     */
    public updateWebmailProtectionInboxesActivity(): void {
        if (this.inboxesActivity.onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.inboxesActivity.markToUpdate = true;
        }
    }

    /**
     * This method is used to retrieve the encryption keys for the webmail protection service and saves them locally.
     * @public
     * @memberof WebmailProtectionService
     * @returns {Observable} Response from server
     */
    public listKeys(): Observable<any> {
        return this.connectWebmailProtectionService.listKeys()
        .pipe(
            map(resp => {
                this.keys = resp;
                return true;
            }),
            catchError(err => {
                this.oAuthErrors.defaultError = true;
                throw err;
            })
        );
    }

    /**
     * Return the currently used key.
     * @returns {WebmailProtectionKey} The currently used key
     */
    private getCurrentlyUsedKey(): WebmailProtectionKey {
        for (const key of this.keys) {
            if (key.type === WebmailProtectionKeySpecificTypes.CURRENTLY_USED) {
                return key;
            }
        }
        return null;
    }

    /**
     * Gets the protection statuses and computes email activity summary for past 30 days.
     * @public
     * @memberof WebmailProtectionService
     * @returns {Observable} Response from server
     */
    public listProtectionStats(): Observable<any> {
        if (!this.getInboxesNumber()) {
            this.summary.byDay = [];
            this.summary.byInbox = [];
            return of(true);
        }

        if (this.summary.onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.inboxesActivity.onList.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            return this.connectWebmailProtectionService.listProtectionStats()
            .pipe(
                map(resp => {
                    if (!resp?.days || !resp?.inboxes) {
                        throw resp;
                    }

                    this.summary.byDay = resp.days;
                    this.summary.byInbox = resp.inboxes;
                    this.summary.error = false;
                    this.summary.onList.next(this.valuesService.processServiceState.DONE);
                    return true;
                }),
                catchError(err => {
                    this.summary.byDay = [];
                    this.summary.byInbox = [];
                    this.summary.error = true;
                    this.summary.onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Gets the summary error.
     * @returns {boolean} True if error occured.
     */
    public getSummaryError(): boolean {
        return this.summary.error;
    }

    /**
     * Gets the array of days with activity summary for the user.
     * @public
     * @memberof WebmailProtectionService
     * @returns {WebmailProtectionSummary[]} The array of days with activity summary.
     */
    public getSummaryByDay(): WebmailProtectionSummary[] {
        return this.summary.byDay ?? [];
    }

    /**
     * Gets the array of inboxes with activity summary for the user.
     * @public
     * @memberof WebmailProtectionService
     * @returns {WebmailProtectionInboxSummary[]} The array of inboxes with activity summary.
     */
    public getSummaryByInbox(): WebmailProtectionInboxSummary[] {
        return this.summary.byInbox ?? [];
    }

    /**
     * Function that updates the inbox activity (pause/resume) according to the value of 'scanActive' param
     *
     * @public
     * @memberof WebmailProtectionService
     * @param {string} emailAddress The email address that will be updated
     * @param {boolean} isScanActive The value for the activity; if set to false, it will pause the scan
     * @returns {Observable} Response from server
     */
    public updateInbox(emailAddress: string, scanActive: boolean): Observable<any> {
        return this.connectWebmailProtectionService.updateInbox(emailAddress, scanActive)
        .pipe(
            map(resp => {
                if (resp?.status === 1) {
                    throw resp;
                }
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Function that removes an inbox.
     * @public
     * @memberof WebmailProtectionService
     * @param {string} emailAddress The email address that will be removed
     * @returns {Observable} Response from server
     */
    public removeInbox(emailAddress: string): Observable<any> {
        return this.connectWebmailProtectionService.removeInbox(emailAddress)
        .pipe(
            map(resp => {
                if (resp?.status === 1) {
                    throw resp;
                }
                return true;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    /**
     * Gets the threats count for an interval.
     * @param {Security.Overview.Filter} filter The interval for the threats.
     * @return {any} The threats count.
     * @memberof SecurityOverviewService
     */
    public getThreatsCount(filter: Security.Overview.Filter): ThreatsCounter[] {
        return this.threatsGroupedByTime[filter].data ?? [];
    }

    /**
     * Gets the threats count error for an interval.
     * @param {Security.Overview.Filter} filter The interval for the threats.
     * @return {boolean} True if error occured
     * @memberof SecurityOverviewService
     */
    public getThreatsCountError(filter: Security.Overview.Filter): boolean {
        return this.threatsGroupedByTime[filter].error;
    }

    /**
     * Gets the threats for an interval.
     * @param {Security.Overview.Filter} filter The interval for the threats.
     * @return {any} The threats.
     * @memberof SecurityOverviewService
     */
    public getThreatsDetails(filter: Security.Overview.Filter): Threat[] {
        return this.threats[filter].data ?? [];
    }

    /**
     * Gets the threats error for an interval.
     * @param {Security.Overview.Filter} filter The interval for the threats.
     * @return {boolean} True if error occured.
     * @memberof SecurityOverviewService
     */
    public getThreatsDetailsError(filter: Security.Overview.Filter): boolean {
        return this.threats[filter].error;
    }

    /**
     * List the grouped threats for the overview page.
     * @param {Security.Overview.Filter} filter The time filter for the threats.
     * @param {number} offset Starting position of the threats inside the list.
     * @return {Observable<any>} The response from the server.
     * @memberof SecurityOverviewService
     */
    public listCountThreats(filter: Security.Overview.Filter): Observable<any> {
        if (this.threatsGroupedByTime[filter].markToUpdate === false) {
            return of(true);
        }

        if (this.threatsGroupedByTime[filter].onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.threatsGroupedByTime[filter].onList.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.threatsGroupedByTime[filter].onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.countThreats(filter)
            .pipe(
                map(resp => {
                    if (resp) {
                        this.threatsGroupedByTime[filter].data = resp;
                        this.threatsGroupedByTime[filter].error = false;
                        this.threatsGroupedByTime[filter].markToUpdate = false;
                        this.threatsGroupedByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    } else {
                        this.threatsGroupedByTime[filter].error = true;
                        this.threatsGroupedByTime[filter].markToUpdate = true;
                        this.threatsGroupedByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    }
                    return of(true);
                }),
                catchError(err => {
                    this.threatsGroupedByTime[filter].error = true;
                    this.threatsGroupedByTime[filter].markToUpdate = true;
                    this.threatsGroupedByTime[filter].onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

     /**
     * Updates the listCountThreats mark to update flags.
     */
     public updateListCountThreats(): void {
        if (this.threatsGroupedByTime[Security.Overview.Filter.LAST_7D].onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.threatsGroupedByTime[Security.Overview.Filter.LAST_7D].markToUpdate = true;
        }

        if (this.threatsGroupedByTime[Security.Overview.Filter.LAST_30D].onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.threatsGroupedByTime[Security.Overview.Filter.LAST_30D].markToUpdate = true;
        }

    }

    /**
     * List the threats details for the overview page.
     * @param {Security.Overview.Filter} filter The time filter for the threats.
     * @param {number} offset Starting position of the threats inside the list.
     * @return {Observable<any>} The response from the server.
     * @memberof SecurityOverviewService
     */
    public listThreats(filter: Security.Overview.Filter, offset?: number): Observable<any> {
        if (offset) {
            this.threats[filter].markToUpdate = true;
        }

        if (this.threats[filter].markToUpdate === false) {
            return of(true);
        }
        const info = this.generateListThreatsDetails(filter, offset);
        if (this.threats[filter].onList.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.threats[filter].onList.asObservable()
                .pipe(
                    skipWhile(res => res !== this.valuesService.processServiceState.DONE)
                );
        } else {
            this.threats[filter].onList.next(this.valuesService.processServiceState.INPROGRESS);
            return this.seccenterService.getThreatsDetails(info)
            .pipe(
                map(resp => {
                    if (resp) {
                        if (offset) {
                            this.threats[filter].data = this.threats[filter].data.concat(resp);
                        } else {
                            this.threats[filter].data = resp;
                        }
                        this.threats[filter].markToUpdate = false;
                        this.threats[filter].error = false;
                        this.threats[filter].onList.next(this.valuesService.processServiceState.DONE);
                    } else {
                        this.threats[filter].error = true;
                        this.threats[filter].markToUpdate = true;
                        this.threats[filter].onList.next(this.valuesService.processServiceState.DONE);
                    }
                }),
                catchError(err => {
                    this.threats[filter].error = true;
                    this.threats[filter].markToUpdate = true;
                    this.threats[filter].onList.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Updates the listThreats mark to update flags.
     */
    public updateListThreats(): void {
        if (this.threats[Security.Overview.Filter.LAST_7D].onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.threats[Security.Overview.Filter.LAST_7D].markToUpdate = true;
        }

        if (this.threats[Security.Overview.Filter.LAST_30D].onList.value !== this.valuesService.processServiceState.INPROGRESS) {
            this.threats[Security.Overview.Filter.LAST_30D].markToUpdate = true;
        }
    }

    /**
     * Generates the listThreats params for the request to get the threats.
     * @private
     * @param {Security.Overview.Filter} filter The time filter for the threats.
     * @param {number} offset Starting position of the threats inside the list.
     * @return {any} The params for the reaquest.
     * @memberof SecurityOverviewService
     */
    private generateListThreatsDetails(filter: Security.Overview.Filter, offset?: number): any {
        const today = new Date();
        const fromDate = new Date(today.getTime() - ((filter - 1) * ONE_DAY_IN_MILISECONDS)).setHours(0,0,0,0);
        const toDate = new Date(today).getTime();
        const params: ThreatsGetDetailsRequestParameters = {
            count: this.webmailProtectionValuesService.overviewPageCount,
            to_date: toDate,
            from_date: fromDate
        };

        if (offset) {
            params.offset = offset;
        }
        return params;
    }

    /**
     * Encryps the goven token with a gven key.
     * @param {CryptoKey} key The encryption key.
     * @param {string} token The token to be encrypted.
     * @returns {Promise<ArrayBuffer>} The encrypted token promise.
     */
    private encryptToken(key: CryptoKey, token: string): Promise<ArrayBuffer> {
        if (!token) {
            return Promise.resolve(null);
        }

        return window.crypto.subtle.encrypt(
            {
                name: WebmailProtectionKeyAlgorithm.RSA_OAEP
            },
            key,
            new TextEncoder().encode(token)
        );
    }

    /**
     * Return the array of promises made for encrypting chunks of maximum 350 characters of the given token.
     * The backend keys can only encrypt token up to 350 characters.
     * @param {CryptoKey} key The key used for encryption.
     * @param {string} token The token to be encrypted.
     * @returns {Promise<ArrayBuffer>[]} The resulting array of promises.
     */
    private encryptTokenChunks(key: CryptoKey, token: string): Promise<ArrayBuffer>[] {
        const encryptionArray = [];
        const tokenChunks = token.match(/.{1,350}/g);
        for (const chunk of tokenChunks) {
            encryptionArray.push(this.encryptToken(key, chunk));
        }
        return encryptionArray;
    }

    /**
     * Creates an ecryption key from the given current key and encrypts the tokens.
     * @returns {Observable<any>} The response from the server.
     */
    public encryptTokens(): Observable<any> {
        const key = this.getCurrentlyUsedKey();

        if (!key) {
            this.oAuthErrors.defaultError = true;
            return throwError(() => new Error(Errors.ERROR));
        }

        const keyData = {
            kty: key.kty,
            e: key.e,
            n: key.n,
            alg: WebmailProtectionKeyAlgorithm.RSA_OAEP_256,
            use: key.use,
            kid: key.kid
        };

        const algorithm = {
            name: WebmailProtectionKeyAlgorithm.RSA_OAEP,
            hash: WebmailProtectionKeyHash.SHA_256
        };

        return defer(() => {
            return window.crypto.subtle.importKey(
                WebmailProtectionKeyFormat.JWK,
                keyData,
                algorithm,
                false,
                [WebmailProtectionKeyUsage.ENCRYPT]
            );
        }).pipe(
            concatMap(importedKey => {
                const accessTokenEncryptionChunks = this.encryptTokenChunks(importedKey, this.tokens.access_token);
                const refreshTokenEncryptionChunks = this.encryptTokenChunks(importedKey, this.tokens.refresh_token);
                const accessTokenEncryptionChunksLength = accessTokenEncryptionChunks.length;
                const refreshTokenEncryptionChunksLength = refreshTokenEncryptionChunks.length;

                if (!accessTokenEncryptionChunksLength || !refreshTokenEncryptionChunksLength) {
                    throw new Error(Errors.ERROR);
                }

                return forkJoin(refreshTokenEncryptionChunks.concat(accessTokenEncryptionChunks))
                .pipe(
                    map((encryptionArrayBuffer: ArrayBuffer[]) => {
                        if (encryptionArrayBuffer?.length !== (accessTokenEncryptionChunksLength + refreshTokenEncryptionChunksLength)) {
                            throw new Error(Errors.ERROR);
                        }

                        const accessToken = [];
                        const refreshToken = [];

                        for (let i = 0; i < refreshTokenEncryptionChunksLength; i++) {
                            refreshToken.push(btoa(String.fromCharCode(...new Uint8Array(encryptionArrayBuffer[i]))));
                        }

                        for (let j = refreshTokenEncryptionChunksLength; j < encryptionArrayBuffer.length; j++) {
                            accessToken.push(btoa(String.fromCharCode(...new Uint8Array(encryptionArrayBuffer[j]))));
                        }

                        this.encryptedTokens.access_token = accessToken;
                        this.encryptedTokens.refresh_token = refreshToken;
                    }),
                    catchError(() => {
                        this.oAuthErrors.defaultError = true;
                        throw new Error(Errors.ERROR);
                    })
                );
            }),
            catchError(() => {
                this.oAuthErrors.defaultError = true;
                throw new Error(Errors.ERROR);
            })
        );
    }
}
