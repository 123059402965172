// External
import { Injectable } from '@angular/core';
import { OperatingSystems, ValuesService } from '../values/values.service';
import { ConfigService } from './config.service';
import { ValuesSupportService } from '../../pages/support/values/values-support.service';
import { LanguageService } from '../services/core/language.service';
import { ProfilesService } from '../services/process/profiles/profiles.service';
import { AppsConfigService } from './apps.config.service';
import { UsefulService } from '../services/global/useful/useful.service';
import { AppLevels } from '../values/subscriptions.values.service';
import { AppByLevelOutlineType, AppByLevelProperties, App } from './ProductsConfig.model';
import { ALL_PLATFORMS } from '../services/interfaces/isubscriptions/isubscriptions.service';

@Injectable({
    providedIn: 'root'
})

export class ProductsConfigService {
    constructor(
        private readonly configService: ConfigService,
        private readonly valuesService: ValuesService,
        private readonly valuesSupportService: ValuesSupportService,
        private readonly languageService: LanguageService,
        private readonly profilesService: ProfilesService,
        private readonly usefulService: UsefulService,
        private readonly appsConfigService: AppsConfigService
    ) {}

    /* PRODUCTS - custom names for each language */
    private readonly productsConfig = {
        [this.valuesService.productNameAV]: {
            /**
             * Example
             * this.valuesService.languages.fr_FR.id: 'custom text',
             *
            */

            default: this.valuesService.productNameAV
        },
        [this.valuesService.productNameIS]: {
            default: this.valuesService.productNameIS
        },
        [this.valuesService.productNameTS]: {
            default: this.valuesService.productNameTS
        },
        [this.valuesService.productNameBMS]: {
            default: this.valuesService.productNameBMS
        },
        [this.valuesService.productNameBIS]: {
            default: this.valuesService.productNameBIS
        },
        [this.valuesService.productNameAVFM]: {
            default: this.valuesService.productNameAVFM
        },
        [this.valuesService.productNamePA]: {
            default: this.valuesService.productNamePA
        },
        [this.valuesService.productNamePANCC]: {
            default: this.valuesService.productNamePANCC
        },
        [this.valuesService.productNameTSMD]: {
            default: this.valuesService.productNameTSMD
        },
        [this.valuesService.productNameFP]: {
            default: this.valuesService.productNameFP
        },
        [this.valuesService.productNameFPAV]: {
            default: this.valuesService.productNameFPAV
        },
        [this.valuesService.productNameFPIS]: {
            default: this.valuesService.productNameFPIS
        },
        [this.valuesService.productNameISMD]: {
            default: this.valuesService.productNameISMD
        },
        [this.valuesService.productNameBoxCl]: {
            default: this.valuesService.productNameBoxCl
        },
        [this.valuesService.productNameBox]: {
            default: this.valuesService.productNameBox
        },
        [this.valuesService.productNameBOX2]: {
            default: this.valuesService.productNameBOX2
        },
        [this.valuesService.productNamePAS]: {
            default: this.valuesService.productNamePAS
        },
        [this.valuesService.productNameAVFREE]: {
            default: this.valuesService.productNameAVFREE
        },
        [this.valuesService.productNameAVLITE]: {
            default: this.valuesService.productNameAVLITE
        },
        [this.valuesService.productNameVPNBasic]: {
            default: this.valuesService.productNameVPNBasic
        },
        [this.valuesService.productNameVPNPremium]: {
            default: this.valuesService.productNameVPNPremium
        },
        [this.valuesService.productNameDIP]: {
            default: this.valuesService.productNameDIP
        },
        [this.valuesService.productNameWallet]: {
            default: this.valuesService.productNameWallet
        },
        [this.valuesService.productNamePasswordManager]: {
            default: this.valuesService.productNamePasswordManager
        },
        [this.valuesService.productNameIDTP]: {
            default: this.valuesService.productNameIDTP
        },
        [this.valuesService.productNameIDTPP]: {
            default: this.valuesService.productNameIDTPP
        },
        [this.valuesService.productNameIDTPS]: {
            default: this.valuesService.productNameIDTPS
        },
        [this.valuesService.productNameUSUS]: {
            default: this.valuesService.productNameUSUS
        },
        [this.valuesService.productNameUSPUS]: {
            default: this.valuesService.productNameUSPUS
        },
        [this.valuesService.productNamePS]: {
            default: this.valuesService.productNamePS
        },
        [this.valuesService.productNameSecurity]: {
            default: this.valuesService.productNameSecurity
        },
        [this.valuesService.productNameBoxDefault]: {
            default: this.valuesService.productNameBoxDefault
        },
        [this.valuesService.productNameWebmailProtection]: {
            default: this.valuesService.productNameWebmailProtection
        },
        [this.valuesService.productNameProtection]: {
            default: this.valuesService.productNameProtection
        }
    };

    /* Manufacturer name - custom name for each language */
    private readonly manufacturerNameConfig = {
        /**
         * Example
         * this.valuesService.languages.fr_FR.id: 'custom text',
        */
        default: this.configService.config.manufacturerName
    };

    /* Bundles */
    private readonly bundlesConfig = {
        [this.valuesService.bundleAVFM]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/avformac.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/antivirus-for-mac.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/antivirus-for-mac.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_mac_2020_userguide_en.pdf?ver=1',
            name: {
                /**
                 * Example
                 * this.valuesService.languages.fr_FR.id: 'custom text',
                 *
                */

                default: this.valuesService.productNameAVFM
            },
            desc: 'subscriptions.description.avformac',
            mainDesc: 'subscriptions.maindescription.avformac'
        },
        [this.valuesService.bundleAVFREE]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/avfree.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/free.html',
            systemRequirements: 'https://www.bitdefender.com/solutions/free.html',
            userGuide: 'https://download.bitdefender.com/windows/bp/Bitdefender_AntivirusFree_2016_en.pdf',
            name: {
                default: `${this.valuesService.productNameAVFREE} Edition`
            },
            desc: 'subscriptions.description.avfree',
            mainDesc: 'subscriptions.maindescription.avfree'
        },
        [this.valuesService.bundleAVLITE]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/avfree.png'),
            // seeAllFeatures: 'https://www.bitdefender.com/solutions/free.html', // TODO
            // systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements', // TODO
            userGuide:'https://www.bitdefender.com/links/%s/2021/help/security-lite.html',
            userGuideLocalize: true,
            name: {
                default: this.valuesService.productNameAVLITE
            },
            desc: 'subscriptions.description.avlite',
            mainDesc: 'subscriptions.maindescription.avlite'
        },
        [this.valuesService.bundleBMS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/bms.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/mobile-security-android.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/mobile-security-android.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_android_2020_userguide_en.pdf?ver=1',
            name: {
                default: `${this.valuesService.productNameBMS} for Android`
            },
            desc: 'subscriptions.description.bms',
            mainDesc: 'subscriptions.maindescription.bms'
        },
        [this.valuesService.bundleBOXSE]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/box.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameBox
            },
            desc: 'subscriptions.description.box1',
            mainDesc: 'subscriptions.maindescription.box1'
        },
        [this.valuesService.bundleBoxCl]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/box.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameBox
            },
            desc: 'subscriptions.description.box_tsmd',
            mainDesc: 'subscriptions.maindescription.box_tsmd'
        },
        [this.valuesService.bundleBOX2]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/boxv2.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/box/v2/user_guide/2020/BOX_UserGuide_v2_en.pdf',
            name: {
                default: this.valuesService.productNameBOX2
            },
            desc: 'subscriptions.description.boxse_tsmd',
            mainDesc: 'subscriptions.maindescription.boxse_tsmd'
        },
        [this.valuesService.bundleAV]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/av.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/antivirus.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/antivirus.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_av_2020_userguide_en.pdf?ver=1',
            name: {
                default: this.valuesService.productNameAV
            },
            desc: 'subscriptions.description.cl.av',
            mainDesc: 'subscriptions.maindescription.cl.av'
        },
        [this.valuesService.bundleIS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/is.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/internet-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/internet-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_is_2020_userguide_en.pdf?ver=1',
            name: {
                default: this.valuesService.productNameIS
            },
            desc: 'subscriptions.description.cl.is',
            mainDesc: 'subscriptions.maindescription.cl.is'
        },
        [this.valuesService.bundleTS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ts_2020_userguide_en.pdf?ver=1',
            name: {
                default: this.valuesService.productNameTS
            },
            desc: 'subscriptions.description.cl.ts',
            mainDesc: 'subscriptions.maindescription.cl.ts'
        },
        [this.valuesService.bundleFP]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/fp.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/family-pack.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/family-pack.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_fp_2020_userguide_en.pdf?ver=1',
            name: {
                default: this.valuesService.productNameFP
            },
            desc: 'subscriptions.description.fp',
            mainDesc: 'subscriptions.maindescription.fp'
        },
        [this.valuesService.bundleFPP]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/fp.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: `${this.valuesService.productNameFP} with ${this.valuesService.productNamePA}`
            },
            desc: 'subscriptions.description.fp.parental',
            mainDesc: 'subscriptions.maindescription.fp.parental'
        },
        [this.valuesService.bundleFPAV]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/fpav.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameFPAV
            },
            desc: 'subscriptions.description.fp.av',
            mainDesc: 'subscriptions.maindescription.fp.av'
        },
        [this.valuesService.bundleFPIS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/fpis.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameFPIS
            },
            desc: 'subscriptions.description.fp.is',
            mainDesc: 'subscriptions.maindescription.fp.is'
        },
        [this.valuesService.bundleIOSBMS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/iosbms.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: `${this.getManufacturerName()} ${this.valuesService.productNameBMS}`,
            name: {
                default: `${this.getManufacturerName()} ${this.valuesService.productNameBMS} for Android & iOS`
            },
            desc: 'subscriptions.description.iosbms',
            mainDesc: 'subscriptions.maindescription.iosbms'
        },
        [this.valuesService.bundleBIS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/iossecurity.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/mobile-security-ios.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/mobile-security-ios.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ios_2020_userguide_en.pdf?ver=1',
            name: {
                default: `${this.valuesService.productNameBIS} for iOS Free`
            },
            desc: 'subscriptions.description.iossecurity',
            mainDesc: 'subscriptions.maindescription.iossecurity'
        },
        [this.valuesService.bundleBIP]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/iosprotection.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: `${this.valuesService.productNameBIP}`,
            name: {
                default: `${this.valuesService.productNameBIP} for iOS`
            },
            desc: 'subscriptions.description.iosprotection',
            mainDesc: 'subscriptions.maindescription.iosprotection'
        },
        [this.valuesService.bundleISMD]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ismd.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameISMD
            },
            desc: 'subscriptions.description.cl.is.multidevice',
            mainDesc: 'subscriptions.maindescription.cl.is.multidevice'
        },
        [this.valuesService.bundlePA]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/parentalsecurity.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNamePA
            },
            desc: 'subscriptions.description.parentaladvisor',
            mainDesc: 'subscriptions.maindescription.parentaladvisor'
        },
        [this.valuesService.bundlePANCC]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/nccparentalsecurity.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNamePANCC
            },
            desc: 'subscriptions.description.parentaladvisor',
            mainDesc: 'subscriptions.maindescription.parentaladvisor'
        },
        [this.valuesService.bundlePSec]: {
            //adaugat in V4!
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurity.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/premium-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/premium-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_premium_security_2020_userguide_en.pdf',
            name: {
                default: this.valuesService.productNamePS
            },
            desc: 'subscriptions.description.premiumsecurity',
            mainDesc: 'subscriptions.maindescription.premiumsecurity'
        },
        [this.valuesService.bundlePSecPlus]: {
            //adaugat in V4!
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurityplus.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/premium-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/premium-security.html#system-requirements',
            //userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_premium_security_2020_userguide_en.pdf',
            userGuideLocalize: false,
            name: {
                default: this.valuesService.productNamePSPlus
            },
            desc: 'subscriptions.description.premiumsecurityplus',
            mainDesc: 'subscriptions.maindescription.premiumsecurityplus'
        },
        [this.valuesService.bundleSecIdentity]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/dip.png'),
            seeAllFeatures: '',
            systemRequirements: 'https://www.bitdefender.com/solutions/password-manager.html',
            name: {
                default: this.valuesService.productNameSecIdentity
            },
            desc: 'subscriptions.description.secureidentity',
            mainDesc: 'subscriptions.maindescription.secureidentity'
        },
        [this.valuesService.bundlePAS]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/parentalsecurity.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNamePAS
            },
            desc: 'subscriptions.description.parentalsecurity',
            mainDesc: 'subscriptions.maindescription.parentalsecurity'
        },
        [this.valuesService.bundleSOHO]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/soho.png'),
            seeAllFeatures: '',
            systemRequirements: 'https://www.bitdefender.com/solutions/small-office-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_soho_2020_userguide_en.pdf',
            name: {
                default: 'Small Office Security'
            },
            desc: 'subscriptions.description.soho',
            mainDesc: 'subscriptions.maindescription.soho'
        },
        [this.valuesService.bundleTSMD]: {
            pic: {
                'nl_NL': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                'de_DE': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                default: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ts_2020_userguide_en.pdf?ver=1',
            name: {
                'nl_NL': this.valuesService.productNameTS,
                'de_DE': `${this.valuesService.productNameTS} Multi-Device`,
                default: `${this.valuesService.productNameTS} Multi-Device`
            },
            desc: 'subscriptions.description.tsmd',
            mainDesc: 'subscriptions.maindescription.tsmd'
        },
        [this.valuesService.bundleCLTSMD]: {
            pic: {
                'nl_NL': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                'de_DE': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                default: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ts_2020_userguide_en.pdf?ver=1',
            name: {
                'nl_NL': this.valuesService.productNameTS,
                'de_DE': `${this.valuesService.productNameTS} Multi-Device`,
                default: `${this.valuesService.productNameTS} Multi-Device`
            },
            desc: 'subscriptions.description.tsmd',
            mainDesc: 'subscriptions.maindescription.tsmd'
        },
        [this.valuesService.bundleVPN]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/vpn.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/vpn.html',
            systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_vpn_2020_userguide_en.pdf',
            name: {
                basic: {
                    default: this.valuesService.productNameVPNBasic
                },
                premium: {
                    default: this.valuesService.productNameVPNPremium
                }
            },
            desc: {
                basic: 'subscriptions.description.vpnbasic',
                premium: 'subscriptions.description.vpnpremium'
            },
            mainDesc: {
                basic: 'subscriptions.maindescription.vpnbasic',
                premium: 'subscriptions.maindescription.vpnpremium'
            }
        },
        [this.valuesService.bundleVPNSOHO]: {
            //adaugat in V4!
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/vpn.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                basic: {
                    default: `${this.valuesService.productNameVPNBasic} For Business`
                },
                premium: {
                    default: `${this.valuesService.productNameVPNPremium} For Business`
                }
            },
            desc: {
                basic: 'subscriptions.description.vpnsoho',
                premium: 'subscriptions.description.vpnsoho'
            },
            mainDesc: {
                basic: 'subscriptions.maindescription.vpnsoho',
                premium: 'subscriptions.maindescription.vpnsoho'
            }
        },
        [this.valuesService.bundleSHS]: {
            //adaugat in V4!
            pic: {
                'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/smart-home-service.png'),
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/smart-home-service.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/premium-services/home-network-support.html',
            systemRequirements: 'https://www.bitdefender.com/premium-services/home-network-support.html',
            userGuide: '',
            name: {
                'en_US': 'Smart Home Security',
                default: 'Smart Home Security'
            },
            desc: 'subscriptions.description.smarthomesecurity',
            mainDesc: 'subscriptions.maindescription.smarthomesecurity'
        },
        [this.valuesService.bundleCTU]: {
            //adaugat in V4!
            pic: {
                'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/computer-tuneup.png'),
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/computer-tuneup.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/premium-services/computer-tune-up.html',
            systemRequirements: 'https://www.bitdefender.com/premium-services/computer-tune-up.html',
            userGuide: '',
            name: {
                'en_US': 'Computer Tune-Up',
                'es_ES': 'Optimizar el Equipo',
                'fr_FR': 'Nettoyage & Optimisation',
                'de_DE': 'Computer-Tuning',
                default: 'Computer Tune-Up'
            },
            desc: 'subscriptions.description.tuneup',
            mainDesc: 'subscriptions.maindescription.tuneup'
        },
        [this.valuesService.bundleSTFPC]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/multi-platform.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html',
            systemRequirements: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html',
            userGuide: '',
            name: {
                default: 'Install and Setup'
            },
            desc: 'subscriptions.description.installandsetup',
            mainDesc: 'subscriptions.maindescription.installandsetup'
        },
        [this.valuesService.bundleSTFM]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/multi-platform.png')
            },
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'Install and Setup for Mac'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundlePCO]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/pc-optimizer.png')
            },
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'PC Optimizer'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundleSYSR]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/system-repair.png')
            },
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'System Repair'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundleVR]: {
            //adaugat in V4!
            pic: {
                'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/virus-exterminator.png'),
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/virus-exterminator.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html',
            systemRequirements: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html',
            userGuide: '',
            name: {
                'en_US': 'Virus and Spyware Removal',
                'fr_FR': 'Suppression de Virus et de Spywares',
                'de_DE': 'Viren- und Sopyware-Entfernung',
                default: 'Virus and Spyware Removal'
            },
            desc: 'subscriptions.description.virusandspyware',
            mainDesc: 'subscriptions.maindescription.virusandspyware'
        },
        [this.valuesService.bundleVIPS]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/vip-support.png')
            },
            seeAllFeatures: 'https://www.bitdefender.com/premium-services/vip-support.html',
            systemRequirements: 'https://www.bitdefender.com/premium-services/vip-support.html',
            userGuide: '',
            name: {
                default: 'VIP Support'
            },
            desc: 'subscriptions.description.vip',
            mainDesc: 'subscriptions.maindescription.vip'
        },
        [this.valuesService.bundleSTMP]: {
            //adaugat in V4!
            pic: {
                'other': this.configService.config.pathImages.concat('subscriptions/bundles/vip-support.png')
            },
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'Install & SetUp Multi-Platform'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundleHVA]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/hva.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/home-scanner.html',
            systemRequirements: 'https://www.bitdefender.com/solutions/home-scanner.html',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2018/userguides/en_EN/Bitdefender_Home_Scanner_en.pdf',
            name: {
                default: 'Home Scanner'
            },
            desc: 'subscriptions.description.hva',
            mainDesc: 'subscriptions.maindescription.hva'
        },
        [this.valuesService.bundleBox]: {
            pic: '',
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNameBox
            },
            desc: 'subscriptions.description.box_tsmd',
            mainDesc: 'subscriptions.maindescription.box_tsmd'
        },
        [this.valuesService.bundleTSVPN]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
            systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ts_2020_userguide_en.pdf?ver=1',
            name: {
                default: `${this.valuesService.productNameTS} & ${this.valuesService.productNameVPNBasic}`
            },
            desc: 'subscriptions.description.tsmdvpn',
            mainDesc: 'subscriptions.maindescription.tsmdvpn'
        },
        [this.valuesService.bundlsSUMP]: {
            pic: '',
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'Setup mp'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundlesSetup]: {
            pic: '',
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: 'Setup'
            },
            desc: '',
            mainDesc: ''
        },
        [this.valuesService.bundleDPI]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/dip.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_dip_2020_userguide_en.pdf?ver=1',
            name: {
                default: this.valuesService.productNameDIP
            },
            desc: 'subscriptions.description.dataprivacy',
            mainDesc: 'subscriptions.maindescription.dataprivacy'
        },
        [this.valuesService.bundleStartPlan]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/start_plan.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: 'Start Plan',
            name: {
                default: 'Antivirus'
            },
            plan: 'Start Plan',
            desc: 'subscriptions.description.startplan',
            mainDesc: 'subscriptions.maindescription.startplan'
        },
        [this.valuesService.bundlePersonalPlan]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: 'Personal Plan',
            name: {
                default: this.valuesService.productNameTS
            },
            plan: 'Personal Plan',
            desc: 'subscriptions.description.personalplan',
            mainDesc: 'subscriptions.maindescription.personalplan'
        },
        [this.valuesService.bundleFamilyPlan]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: 'Family Plan',
            name: {
                default: this.valuesService.productNameTS
            },
            plan: 'Family Plan',
            desc: 'subscriptions.description.familyplan',
            mainDesc: 'subscriptions.maindescription.familyplan'
        },
        [this.valuesService.bundleUltimatePlan]: {
            pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            supportName: 'Ultimate Plan',
            name: {
                default: this.valuesService.productNameTS
            },
            plan: 'Ultimate Plan',
            desc: 'subscriptions.description.ultimateplan',
            mainDesc: 'subscriptions.maindescription.ultimateplan'
        },
        [this.valuesService.bundlePM]: {
            pic: this.valuesService.centralImages.passwordManagerBundle.link,
            seeAllFeatures: '',
            systemRequirements: '',
            userGuide: '',
            name: {
                default: this.valuesService.productNamePasswordManager
            },
            desc: 'subscriptions.description.passmanager',
            mainDesc: 'subscriptions.maindescription.passmanager'
        },
        [this.valuesService.bundleIDTPS]: {
            pic: this.valuesService.centralImages.idTheft.link,
            seeAllFeatures: 'https://www.bitdefender.com/solutions/identity-theft-protection.html',
            userGuide:'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_idtheft_protection_2020_userguide_en.pdf',
            userGuideLocalize: false,
            name: {
                default: this.valuesService.productNameIDTPS
            },
            desc: 'subscriptions.description.idtps',
            mainDesc: 'subscriptions.maindescription.idtps'
        },
        [this.valuesService.bundleIDTPP]: {
            pic: this.valuesService.centralImages.idTheft.link,
            seeAllFeatures: 'https://www.bitdefender.com/solutions/identity-theft-protection.html',
            userGuide:'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_idtheft_protection_2020_userguide_en.pdf',
            userGuideLocalize: false,
            name: {
                default: this.valuesService.productNameIDTPP
            },
            desc: 'subscriptions.description.idtpp',
            mainDesc: 'subscriptions.maindescription.idtpp'
        },
        [this.valuesService.bundleUSUS]: {
            pic: this.valuesService.centralImages.ultimateSecurity.link,
            seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html',
            systemRequirements:'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
            // userGuide: "https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ultimate_security_2020_userguide_en.pdf",
            userGuideLocalize: false,
            name: {
                default: this.valuesService.productNameUSUS
            },
            desc: 'subscriptions.description.usus',
            mainDesc: 'subscriptions.maindescription.usus'
        },
        [this.valuesService.bundleUSPUS]: {
            pic: this.valuesService.centralImages.ultimateSecurity.link,
            seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html',
            systemRequirements: 'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
            // userGuide:'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_ultimate_security_2020_userguide_en.pdf',
            userGuideLocalize: false,
            name: {
                default: this.valuesService.productNameUSPUS
            },
            desc: 'subscriptions.description.uspus',
            mainDesc: 'subscriptions.maindescription.uspus'
        }
    };

    /* Apps */
    private readonly appByLevelInfo: AppByLevelOutlineType = {
        [ALL_PLATFORMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameTS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppNameTotalSecurityMultiPlatform()
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameTS}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            }
        },
        [this.valuesService.appAVFM]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: 'Antivirus',
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appAVFM)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    /**
                     * Example
                     * this.valuesService.languages.fr_FR.id: 'custom text',
                     *
                    */
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameAVFM}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFM
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/avformac.png')
            }
        },
        [this.valuesService.appCL]: {
            [AppLevels.AV]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameAV,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameAV}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAV
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/av.png')
            },
            [AppLevels.IS]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameIS}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIS
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/is.png')
            },
            [AppLevels.TS]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameTS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameTS}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            },
            [AppLevels.EPP]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' Antivirus')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/start_plan.png')
            },
            [AppLevels.EPP_PRIVACY]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameTS}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' Antivirus')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/start_plan.png')
            }
        },
        [this.valuesService.appAVFREE]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameAVFREE}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFREE
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/avfree.png')
            }
        },
        [this.valuesService.appOptimizeDvm]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: ''
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: ''
                },
                [AppByLevelProperties.ICON]: ''
            }
        },
        [this.valuesService.appBMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBMS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBMS)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameBMS} for Android`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android`
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/bms.png')
            }
        },
        [this.valuesService.appIOSBMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameBMS} for Android and iOS`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android and iOS`
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/iosbms.png')
            }
        },
        [this.valuesService.appBIP]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBIP,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBIP)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameBIP} for iOS`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIP} for iOS`
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/iosprotection.png')
            }
        },
        [this.valuesService.appBIS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBIS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBIS)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameBIS} for iOS Free`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIS} for iOS Free`
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/iossecurity.png')
            }
        },
        [this.valuesService.appPA]: {
            [AppLevels.BASIC]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNamePA}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/parentalpremium.png')
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNamePA}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/parentalpremium.png')
            }
        },
        [this.valuesService.appPANCC]: {
            [AppLevels.BASIC]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNamePANCC}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/nccparentalsecurity.png')
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNamePANCC}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/nccparentalsecurity.png')
            }
        },
        [this.valuesService.appBOX1]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' Basic Network Security ')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Basic Network Security '
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/box.png')
            }
        },
        [this.valuesService.appBOX2]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' Advanced Network Security')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Advanced Network Security'
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/boxv2.png')
            }
        },
        [this.valuesService.appVPN]: {
            [AppLevels.BASIC]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameVPNBasic,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appVPN)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameVPNBasic}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameVPNBasic
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/vpn.png')
            },
            [AppLevels.PREMIUM]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameVPNPremium,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appVPN)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' VPN Premium')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'VPN Premium'
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/vpnpremium.png')
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameVPNBasic}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameVPNBasic
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/vpn.png')
            }
        },
        [this.valuesService.appDIP]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameDIP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameDIP}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameDIP
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/dip.png')
            }
        },
        [this.valuesService.appHVA]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.getManufacturerName().concat(' Home Scanner')
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Home Scanner'
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/hva.png')
            }
        },
        [this.valuesService.appPassManager]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePasswordManager,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNamePasswordManager}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePasswordManager
                },
                [AppByLevelProperties.ICON]: this.valuesService.centralImages.passwordManagerBundle.link
            }
        },
        [this.valuesService.appIdTheft]: {
            [AppLevels.STANDARD]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTPS,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameIDTPS}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPS
                },
                [AppByLevelProperties.ICON]: this.valuesService.centralImages.idTheft.link
            },
            [AppLevels.PREMIUM]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTPP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameIDTPP}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPP
                },
                [AppByLevelProperties.ICON]: this.valuesService.centralImages.idTheft.link
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameIDTP}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTP
                },
                [AppByLevelProperties.ICON]: this.valuesService.centralImages.idTheft.link
            }
        },
        [this.valuesService.appWebmailProtection]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameWebmailProtection}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameWebmailProtection
                },
                [AppByLevelProperties.ICON]: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
            }
        },
        [this.valuesService.appProtection]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameProtection}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameProtection
                },
                [AppByLevelProperties.ICON]: ''
            }
        },
        [this.valuesService.appSecurity]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.getManufacturerName()} ${this.valuesService.productNameSecurity}`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameSecurity
                },
                [AppByLevelProperties.ICON]: ''
            }
        }
    };

    /* Products Info */
    private readonly supportProductsConfig = [
        {},
        {
            bundle_id: this.valuesSupportService.allProducts.box2,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.box2)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.box2],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.box2)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.totalSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.totalSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.totalSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.totalSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.internetSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.internetSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.internetSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.internetSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.antivirusFree,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.antivirusFree)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.antivirusFree],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.antivirusFree)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.antivirusPlus,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.antivirusPlus)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.antivirusPlus],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.antivirusPlus)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.avForMac,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.avForMac)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.avForMac],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.avForMac)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.familyPack,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.familyPack)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.familyPack],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.familyPack)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.premiumSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.premiumSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.premiumSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.premiumSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.soho,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.soho)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.soho],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.soho)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.bms,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.security,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.bms),
                operatingSystem: this.valuesService.compatibleOsInfo[OperatingSystems.ANDROID].display
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.bms],
            subject: this.getManufacturerNameEnglish().concat(' ', this.getBundleNameEnglish(this.valuesSupportService.allProducts.bms),
                    ' for ', this.valuesService.compatibleOsInfo[OperatingSystems.ANDROID].display)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.iosProtection,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.security,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.iosProtection),
                operatingSystem: this.valuesService.compatibleOsInfo[OperatingSystems.IOS].display
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.iosProtection],
            subject: this.getManufacturerNameEnglish().concat(' ', this.getBundleSupportName(this.valuesSupportService.allProducts.iosProtection),
                    ' for ', this.valuesService.compatibleOsInfo[OperatingSystems.IOS].display)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.vpn,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.vpn, this.valuesService.subscriptionsLevels.basic)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.vpn],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.vpn, this.valuesService.subscriptionsLevels.basic)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.passManager,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.passManager)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.passManager],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.passManager)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.dataPrivacy,
            label: {
                product: () => this.getBundleName(this.valuesSupportService.allProducts.dataPrivacy)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.dataPrivacy],
            subject: this.getBundleNameEnglish(this.valuesSupportService.allProducts.dataPrivacy)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.startPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.startPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.startPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.startPlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.familyPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.familyPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.familyPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.familyPlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.ultimatePlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.ultimatePlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.ultimatePlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.ultimatePlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.personalPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.personalPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.personalPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.personalPlan)}`
        },
        {
            bundle_id: '',
            label: {
                text: 'support.form.opt.product.other'
            },
            value: this.valuesSupportService.productMapping.other,
            subject: this.valuesSupportService.defaultFormSubject
        }
    ];

    /* Apps Info */
    private readonly devicesAppsConfig = {
        [this.valuesService.appAVFM] : {
            appId : this.valuesService.appAVFM,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.osx',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appAVFREE] : {
            appId : this.valuesService.appAVFREE,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.free.protection.windows',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appCL] : {
            appId : this.valuesService.appCL,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.windows',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBMS] : {
            appId : this.valuesService.appBMS,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.android',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBIP] : {
            appId : this.valuesService.appBIP,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.ios',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBIS] : {
            appId : this.valuesService.appBIS,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.free.protection.ios',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appPA] : {
            appId : this.valuesService.appPA,
            icon: this.valuesService.appsIcons.PARENTAL,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNamePA),
            translate: false,
            genericName: 'devices.details.apps.others'
        },
        [this.valuesService.appPANCC] : {
            appId : this.valuesService.appPANCC,
            icon: this.valuesService.appsIcons.PARENTALNCC,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNamePANCC),
            translate: false,
            genericName: 'devices.details.apps.others'
        },
        [this.valuesService.appVPN] : {
            appId : this.valuesService.appVPN,
            icon: this.valuesService.appsIcons.VPN,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNameVPNBasic),
            translate: false,
            genericName: 'devices.details.apps.others'
        }
    };

    /**
     * Computes the subtitle for an app in the new install modal
     * @param appId The app id
     * @returns {string} The subtitle
     */
    private computeInstallModalPartialAppName(appId: string): string {
        const availableOses = this.appsConfigService.getAvailableOses(appId);
        const displayOses = [];
        for (const os of availableOses) {
            if (os === OperatingSystems.OSX) {
                continue;
            }
            displayOses.push(this.usefulService.getDeviceOSForDisplay(os));
        }
        return 'for '.concat(displayOses.join(', '));;
    }

    /**
     * Computes the subtitle for the total security multi platform app in the new install modal
     * @returns {string} The subtitle for the multi platform total security app in the new install modal
     */
    private computeInstallModalPartialAppNameTotalSecurityMultiPlatform(): string {
        let availableOses = [];
        const installModalTotalSecurityApps = this.appsConfigService.getInstallModalTotalSecurityApps();
        const displayOses = [];

        for (const appId of installModalTotalSecurityApps) {
            availableOses = availableOses.concat(this.appsConfigService.getAvailableOses(appId));
        }

        for (const os of availableOses) {
            if (os === OperatingSystems.OSX) {
                continue;
            }
            displayOses.push(this.usefulService.getDeviceOSForDisplay(os));
        }
        return 'for '.concat(displayOses.join(', '));
    }

    /**
     * Gets the products for the support form
     * @returns {object[]} The products for the support form
     */
    public getSupportProducts(): object[] {
        return this.supportProductsConfig;
    }

    /**
     * Gets the bundle id for a certain product from support form
     * @param index The index of the product
     * @returns {string} The bundle id
     */
    public getSupportProductBundleId(index: number): string {
        return this.supportProductsConfig[index]?.bundle_id;
    }

    /**
     * Gets the display name for a certain product from support form
     * @param index The index of the product
     * @returns {string} The display name
     */
    public getSupportProductDisplayName(index: number): string {
        return this.supportProductsConfig[index]?.value;
    }

    /**
     * Gets the ticket subject for a certain product from support form
     * @param index The index of the product
     * @returns {string} The ticket subject
     */
    public getSupportProductSubject(index: number): string {
        const partialSubject = this.supportProductsConfig[index]?.subject ?? this.valuesSupportService.defaultFormSubject;
        const fromCentralStrong = 'From Central: ';
        return fromCentralStrong.concat(partialSubject);
    }

    /**
     * Get box product name
     * @param {nothing}
     * @returns {string} box product name
     */
     getBoxProductName() {
        return this.getManufacturerName().concat(' ', this.getProductName(this.valuesService.productNameBoxDefault));
    }

    /**
     * Get product name based on language detected
     * @param {string} name
     * @returns {string} product name
     */
    getProductName(name) {
        const lang = this.languageService.getLang();
        let productName = '';

        if (this.productsConfig?.[name]) {
            productName = (this.productsConfig?.[name]?.[lang]) ? this.productsConfig[name][lang] : this.productsConfig[name].default;
        }
        return productName;
    }

    /**
     * Get product name in english
     * @param {string} name
     * @returns {string} product name
     */
    getProductNameEnglish(name) {
        let productName = '';

        if (this.productsConfig?.[name]) {
            productName = (this.productsConfig?.[name]?.[this.valuesService.languages.en_US.id])
                            ? this.productsConfig[name][this.valuesService.languages.en_US.id]
                            : this.productsConfig[name].default;
        }
        return productName;
    }

    /**
     * Get manufacturer name based on language detected
     * @param {nothing}
     * @returns {string} manufacturer name
     */
    getManufacturerName() {
        const lang = this.languageService.getLang();
        return this.manufacturerNameConfig?.[lang] ?? this.manufacturerNameConfig.default;
    }

    /**
     * Get manufacturer name in english
     * @param {nothing}
     * @returns {string} manufacturer name
     */
    getManufacturerNameEnglish() {
        return this.manufacturerNameConfig?.[this.valuesService.languages.en_US.id] ?? this.manufacturerNameConfig.default;
    }

    /**
     * Get bundle
     * @param {string} bundleId
     * @returns {object} bundle
     */
    getBundle(bundleId) {
        return this.bundlesConfig?.[bundleId] ?? null;
    }

    /**
     * Get bundle name based on level and identified language
     * @param {string} bundleId
     * @param {string} level
     * @returns {string} bundle name
     */
    public getBundleName(bundleId ,level?): string {
        const lang = this.languageService.getLang();
        return this.bundlesConfig?.[bundleId]?.name?.[level]?.[lang]
                || this.bundlesConfig?.[bundleId]?.name?.[level]?.default
                || this.bundlesConfig?.[bundleId]?.name?.[lang]
                || this.bundlesConfig?.[bundleId]?.name?.default
                || '';
    }

    /**
     * Get bundle name in english based on level
     * @param {string} bundleId
     * @param {string} level
     * @returns {string} bundle name
     */
    public getBundleNameEnglish(bundleId ,level?): string {
        const lang = this.valuesService.languages.en_US.id;
        return this.bundlesConfig?.[bundleId]?.name?.[level]?.[lang]
                || this.bundlesConfig?.[bundleId]?.name?.[level]?.default
                || this.bundlesConfig?.[bundleId]?.name?.[lang]
                || this.bundlesConfig?.[bundleId]?.name?.default
                || '';
    }

    /**
     * Get bundle support name
     * @param {string} bundleId
     * @returns {string} bundle support name
     */
    private getBundleSupportName(bundleId): string {
        return this.bundlesConfig?.[bundleId]?.supportName ?? '';
    }


    /**
     * Get icon of app
     * @param app   app
     * @returns     icon
     */
    public getAppIcon(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.ICON]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE][AppByLevelProperties.ICON]
                || '';
    }

    /**
     * Get app name based on level, identified language and subscriptions version
     * @param {App} app
     * @returns {string} app name
     */
    public getAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        const name = !this.profilesService.ownerHasSubsV4() ? AppByLevelProperties.NAME_V3 : AppByLevelProperties.NAME;

        return this.appByLevelInfo?.[appId]?.[level]?.[name]?.[lang]
                || this.appByLevelInfo?.[appId]?.[level]?.[name]?.[AppByLevelProperties.DEFAULT]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[name]?.[lang]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[name]?.[AppByLevelProperties.DEFAULT]
                || '';
    }

     /**
     * Get app name for subscriptions version v4 based on level and identified language
     * @param {App} app
     * @returns {string} app name
     */
     public getV4AppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();

        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.NAME]?.[lang]
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.NAME]?.[AppByLevelProperties.DEFAULT]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.NAME]?.[lang]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.NAME]?.[AppByLevelProperties.DEFAULT]
                || '';
    }

    /**
     * Gets the first part of the app name for the new install modal
     * @param app The app to get the upper install app name for
     * @returns {string} The first part of the app name
     */
    public getUpperInstallAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.UPPER]
                || '';
    }

    /**
     * Gets the second part of the app name for the new install modal
     * @param app The app to get the upper install app name for
     * @returns {string} The second part of the app name
     */
    public getLowerInstallAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.LOWER]()
                || '';
    }

    /**
     * Get appInfo with updated product name
     * @param {string} appId
     * @returns {object} app info
     */
    public getDevicesAppInfo(appId: string) {
        const {nameFunction, ...appInfoNoFunction} = this.devicesAppsConfig[appId];
        appInfoNoFunction.name = this.devicesAppsConfig[appId].nameFunction();
        return appInfoNoFunction;
    }

}
